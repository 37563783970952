@import "node_modules/bootstrap/scss/bootstrap-grid";

// Abstracts
@import "./abstracts/variables.scss";
@import "./abstracts/mixins.scss";

// Base
@import "./base/init.scss";
@import "./base/heading.scss";
@import "./base/layout.scss";
@import "./base/typography.scss";
@import "./base/container.scss";
@import "./base/background.scss";
@import "./base/button.scss";
@import "./base/carousel.scss";
@import "./base/forms.scss";
@import "./base/list.scss";
@import "./base/alert.scss";
@import "./base/scroll-in-view.scss";

// Sections de contenu
@import "./sections/header-nav-section.scss";
@import "./sections/footer-nav-section.scss";
@import "./sections/footer-plan-section.scss";
@import "./sections/frame-type-accordion.scss";
@import "./sections/frame-type-card_group.scss";
@import "./sections/frame-type-icon_group.scss";
@import "./sections/frame-type-slider.scss";
@import "./sections/frame-type-text.scss";
@import "./sections/frame-type-textpic-2cols.scss";

// Blocks
@import "./blocks/frame-button.scss";
@import "./blocks/social-links.scss";
@import "./blocks/breadcrumb.scss";
@import "./blocks/page-header.scss";

// Pages
@import "./pages/home.scss";

// Theme
@import "./themes/theme-petit-tonnerre.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
