
.collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.collapse {
    &:not(.show) {
        display: none;
    }
}