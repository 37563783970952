.frame-button {
    display: flex;
    justify-content: center;

    &--left {
        justify-content: flex-start;
    }

    &--right {
        justify-content: flex-end;
    }
}