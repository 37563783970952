.frame-type-textpic-2cols {
    position: relative;

    overflow: hidden;
    .container--wide {
        @include breakpoint(sm) {
            padding: 0 30px;
        }
        @include breakpoint(xl) {
            padding:  0 70px;
        }
    }
    .ce-textpic {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;

      @include breakpoint(lg) {
        flex-direction: row;
      }

        .ce-bodytext {
            width: 100%;
            @include breakpoint(lg) {
                width: calc(55% - 40px);
            }
            @include breakpoint(xl) {
                width: calc(55% - 60px);
            }
        }
        .ce-gallery {
            position: relative;
            z-index: 1;

            width: 100%;
            max-width: 400px;

            margin-top: 30px;

            @include breakpoint(lg) {
                width: 45%;
                max-width: 45%;

                margin: 0;
            }
            @include breakpoint(xl) {
                width: 45%;
            }
        }

        &.ce-left {
            @include breakpoint(md) {
                flex-direction: row;
            }
        }
        &.ce-right {
            @include breakpoint(md) {
                flex-direction: row-reverse;
                // text-align: right;
            }
        }
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;

        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    // Styles spécifiques
    .ce-bodytext {
        &__wrapper {
            position: relative;

            margin-top: 30px;

            @include breakpoint(md) {
                margin-top: 0px;

                column-count: 2;
                column-gap: 40px;
            }

            & > * {
                position: relative;
                z-index: 1;
            }
        }
    }
}

// Bg gray 
.bg--gray,
.frame-type-textpic-2cols.frame--gray {
    background-color: $colorGrayLight;
}

.variant--rounded {
    .frame-type-textpic-2cols {
        .image {
            border-radius: 18px;
        }
    }
}
  
.variant--oblong {
    .frame-type-textpic-2cols {
        .image {
            border-radius: 27px;
        } 
    }
}
