$ce: ".frame-type-slider";

#{$ce} {
    &.frame-slider-main {
        padding: 0;
        .slide {
            height: 400px;
            @include breakpoint(md) {
                height: 600px;
                font-size: calc(1.25 * #{$fontPrimarySize});
            }
            @include breakpoint(lg) {
                height: 720px;
                font-size: calc(1.375 * #{$fontPrimarySize});
            }
            @include breakpoint(xl) {
                font-size: calc(1.5 * #{$fontPrimarySize});
            }
        }
        .carousel--arrows-left {
            .slide {
                height: 540px;
                @include breakpoint(md) {
                    height: 600px;
                }
                @include breakpoint(lg) {
                    height: 720px;
                }
            }
        }
    }

    .frame-header {
        margin-bottom: 40px;

        text-align: center;
    }

    .frame-button {
        display: flex;
        justify-content: center;

        margin-top: 40px;
    }

    .container--wide {
        width: 100%;
        max-width: 100%;
    }

    .slide {
        @include breakpoint(md) {
            font-size: calc(1.25 * #{$fontPrimarySize});
        }
        @include breakpoint(xl) {
            font-size: calc(1.375 * #{$fontPrimarySize});
        }
        h3 {
            @include breakpoint(xl) {
                font-size: calc(2 * #{$fontPrimarySize});
            }
        }
    }

    // Carousel disabled
    .carousel {
        &:not(.flickity-enabled) {
            @include breakpoint(xl) {
                height: 700px;
            }
            .slide {
                position: relative;
                z-index: 2;

                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

.bg--gray,
.frame-type-slider.frame--gray {
    .carousel--reviews-arrows-inside,
    .carousel--reviews-arrows-outside {
        .slide-container {
            background-color: $white;
        }
    }
}