html {
    font-size: 62.5%;
}
body {
    font-size: 1.4rem;

    @include breakpoint(md) {
        font-size: 1.6rem;
    }
    @include breakpoint(xl) {
        font-size: 1.8rem;
    }
}
strong {
    font-weight: bolder;
}
p {
    margin: 16px 0;
    line-height: 1.5;
}
ul,
ol {
    list-style-position: outside;

    padding-left: 30px;
    margin-bottom: 16px;

    li {
        & + li {
            margin-top: 8px;
        }
    }
}
blockquote {
    position: relative;

    border: 1px solid $colorGray;
    padding: 22px 28px;
    margin: 26px 17px;

    @include breakpoint(md) {
        padding: 33px 45px;
    }
    @include breakpoint(xl) {
        padding: 40px 70px;
        margin: 30px 0;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;

        width: 35px;
        height: 25px;

        padding: 8px;

        background-color: $white;
        @include breakpoint(md) {
            width: 50px;
            height: 36px;

            padding: 15px;
        }
        @include breakpoint(xl) {
            width: 79px;
            height: 57px;

            padding: 20px;
        }
    }
    &::before {
        content: url('../../assets/img/quote-before.svg');
        top: -19px;
        left: -26px;
        @include breakpoint(md) {
            top: -33px;
            left: -40px;
        }
        @include breakpoint(xl) {
            top: -48px;
            left: -59px;
        }
    }
    &::after {
        content: url('../../assets/img/quote-after.svg');
        bottom: -19px;
        right: -26px;
        @include breakpoint(md) {
            bottom: -33px;
            right: -40px;
        }
        @include breakpoint(xl) {
            bottom: -48px;
            right: -59px;
        }
    }
}
.text {
    // Color
    &--primary {
        color: $colorPrimary;

        &-light {
            color: $colorPrimaryLight;
        }

        &-dark {
            color: $colorPrimaryDark;
        }
    }

    &--secondary {
        color: $colorSecondary;
    }

    &--invert {
        color: $textColor;
    }

    // Weight
    &--bold {
        font-weight: 700;
    }

    &--normal {
        font-weight: 400;
    }

    &--light {
        font-weight: 200;
    }

    // Align
    &--center {
        text-align: center;
    }

    &--left {
        text-align: left;
    }

    &--right {
        text-align: right;
    }

    &--justify {
        text-align: justify;
    }

    // Size
    &--sm {
        font-size: 0.8rem;
    }

    &--md {
        font-size: 1rem;
    }

    &--lg {
        font-size: 1.25rem;
    }

    &--xl {
        font-size: 1.5rem;
    }

    &--2xl {
        font-size: 2rem;
    }

    &--4xl {
        font-size: 3rem;
    }
}

.variant--rounded {
    blockquote {
        border-radius: 18px;
    }           
}

.variant--oblong {
    blockquote {
        border-radius: 27px;
    } 
}