.social-links {
    display: flex;
    align-items: center;

    list-style-type: none;
    padding: 0;

    font-size: 2.4rem;

    &__item {
        & + .social-links__item {
            margin: 0 0 0 10px;
        }
    }
    a {
        line-height: 1;
        font-size: 4rem;

        color: $textColor;

        &:hover {
            color: $colorSecondary;
        }
    }
}