.frame-type-accordion {
    .frame-header {
        margin-bottom: 40px;
    }
    .accordion {
        &-button {
            display: block;
            position: relative;

            width: 100%;

            background-color: $colorAccordionBg;
            border: none;
            border-bottom: 1px solid $colorAccordionBorder;

            padding: 20px 46px 20px 22px;

            font-weight: 700;
            text-align: left;

            cursor: pointer;

            @include breakpoint(lg) {
                font-size: calc(1.25 * #{$fontPrimarySize});
            }
            @include breakpoint(xl) {
                font-size: calc(1.375 * #{$fontPrimarySize});
            }

            &[aria-expanded="true"] {
                background-color: $colorGrayLight;
                color: $colorPrimary;
                &::after {
                    transform: rotate(90deg);

                    transition: transform 100ms ease-in;
                }
            }

            &::after {
                content: '';
                position: absolute;
                top: calc(50% - 8px);
                right: 20px;

                width: 16px;
                height: 16px;

                background-image: url('../../assets/svg/chevron-right.svg');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

                transform: rotate(0deg);

                transition: transform 100ms ease-in;
            }
        }
        &-header {
            margin: 0;
        }
        &-body {
            padding: 10px 22px;
            background-color: $colorAccordionActiveBg;
        }
        /* &-collapse {
            height: 0;
            overflow: hidden;
        } */
    }

    &.frame-header--center {
        .frame-header {
            text-align: center;
        }
    }

    &.frame-font--primary {
        * {
            color: $colorPrimary;
        }
    }
}