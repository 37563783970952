.footer-plan {
    background-color: $colorFooterBg;
    font-size: $fontPrimarySize;
    * {
        color: $colorFooterText;
    }
    .container--wide {
        .footer-plan__container {
            max-width: 1740px;
        }
    }
    .footer-plan__container {
        padding: 50px 0;
        width: 90%;
        max-width: 1140px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        @include breakpoint(md) {
            flex-direction: row;
        }
        &--wide {
            max-width: 1740px;
        }
    }
    .footer-plan__item {
        margin: 0 10px;
        margin-bottom: 25px;
        // padding-left: 10px;
        // border-left: 1px solid $white;
        @include breakpoint(md) {
            margin-bottom: 0;
            flex: 1 1 48%;
        }
        @include breakpoint(xl) {
            flex: 1 1 24%;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .footer-plan__title {
        margin-top: 0;
        margin-bottom: 20px;
        
        font-size: calc(1.125 * #{$fontPrimarySize});
        font-weight: 700;

        @include breakpoint(xl) {
            font-size: calc(1.375 * #{$fontPrimarySize});
        }
    }
    .footer-plan__list {
        list-style-type: none;
        padding: 0;
        li {
            & + li {
                margin-top: 10px;
            }
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .footer-plan__icon {
        .social-links {
            justify-content: flex-start;
        }
    }
    .footer-plan__logo {
        max-width: 235px;
    }
    
    &.frame-bg--dark {
        background-color: $gray-800;
        * {
            color: $white;
        }
    }

    &.frame-bg--white {
        background-color: $white;
        * {
            color: $black;
        }
    }

    &.frame-font--primary {
        * {
            color: $colorPrimary;
        }
    }
}