.heading {
    font-weight: 700;
    line-height: 1;
}

h1,
.heading--h1 {
    font-size: calc(1.75 * #{$fontSecondarySize});
    font-weight: 700;
    font-family: $fontSecondary;
    line-height: 1;

    @include breakpoint(xl) {
        font-size: calc(2 * #{$fontSecondarySize});
    }
}

h2,
.heading--h2 {
    font-size: calc(1.5 * #{$fontSecondarySize});
    font-weight: 700;
    font-family: $fontSecondary;
    line-height: 1;

    margin-bottom: 20px;

    @include breakpoint(xl) {
        font-size: calc(1.75 * #{$fontSecondarySize});
    }
}

h3,
.heading--h3 {
    font-size: calc(1.1875 * #{$fontPrimarySize});
    font-weight: 700;
    line-height: 1;

    margin-bottom: 18px;

    @include breakpoint(xl) {
        font-size: calc(1.375 * #{$fontPrimarySize});

        margin-bottom: 20px;
    }
}

h4,
.heading--h4 {
    font-size: $fontPrimarySize;
    font-weight: 700;
    line-height: 1;

    margin-bottom: 16px;
    @include breakpoint(xl) {
        font-size: calc(1.125 * #{$fontPrimarySize});

        margin-bottom: 16px;
    }
}