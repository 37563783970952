.frame-type-icon_group {

    .frame-header {
        margin-bottom: 40px;

        text-align: center;
    }

    .icongroup {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        a.icongroup-item {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &-item {
            --nbr-icon: 5;
            width: calc((100% / var(--nbr-icon)) - (25px * (var(--nbr-icon) - 2)));
            min-width: 160px;
            max-width: 255px;

            font-weight: bold;
            font-size: $fontPrimarySize;

            margin-top: 8px;
            margin-bottom: 8px;
    
            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 90%;
                max-width: 130px;

                margin-left: auto;
                margin-right: auto;
    
                img {
                    width: 100%;
                    height: 100%;
    
                    object-fit: contain;
                }
            }
    
            &-text {
                margin-top: 20px;
    
                color: $colorIconTitle;
    
                text-align: center;
            }
        }

        &.icongroup-align-start {
            justify-content: center;
            @include breakpoint(xs) {
                justify-content: flex-start;
            }
        }
        &.icongroup-align-end {
            justify-content: center;
            @include breakpoint(xs) {
                justify-content: flex-end;
            }
        }
        &.icongroup-align-center {
            justify-content: center;
            @include breakpoint(xs) {
                justify-content: center;
            }
        }
        &.icongroup-align-space {
            justify-content: center;
            @include breakpoint(xs) {
                justify-content: space-between;
            }
        }
        &.icongroup-columns-auto {
            .icongroup-item {
                flex: 1 1 auto;
                width: 100%;

                @include breakpoint(xs) {
                    width: auto;
                }
            }
        }
        // Calcul du colonnage :
        &.icongroup-columns-6,
        &.icongroup-columns-5,
        &.icongroup-columns-4,
        &.icongroup-columns-3,
        &.icongroup-columns-2 {
            .icongroup-item {
                width: 100%;
                max-width: 100%;
                @include breakpoint(xs) {
                    width: calc(50% - 30px);
                    max-width: calc(50% - 30px);
                } 
            }  
        }
        &.icongroup-columns-6 {
            .icongroup-item { 
                @include breakpoint(md) {
                    width: calc(33% - 30px);
                    max-width: calc(33% - 30px);
                } 
                @include breakpoint(xl) {
                    width: calc(16% - 30px);
                    max-width: calc(16% - 30px);
                }  
            }  
        }
        &.icongroup-columns-5 {
            .icongroup-item {
                @include breakpoint(md) {
                    width: calc(33% - 30px);
                    max-width: calc(33% - 30px);
                } 
                @include breakpoint(xl) {
                    width: calc(20% - 30px);
                    max-width: calc(20% - 30px);
                }  
            }  
        }
        &.icongroup-columns-4 {
            .icongroup-item { 
                @include breakpoint(lg) {
                    width: calc(25% - 30px);
                    max-width: calc(25% - 30px);
                }  
            }  
        }
        &.icongroup-columns-3 {
            .icongroup-item { 
                @include breakpoint(lg) {
                    width: calc(33% - 30px);
                    max-width: calc(33% - 30px);
                }  
            }  
        }
        &.icongroup-columns-1 {
            .icongroup-item {
                width: 100%;
            }
        }
        &.icongroup-iconposition-left {
            .icongroup-item {
                display: flex;
                align-items: center;

                font-size: $fontPrimarySize;

                &-icon {
                    width: 60px;
                    flex-shrink: 0;

                    margin-right: 18px;
                    margin-left: 0;
                }
                &-text {
                    text-align: left;
                    margin: 0;
                }
            }
        }
        &.icongroup-iconposition-right {
            .icongroup-item {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                font-size: $fontPrimarySize;

                &-icon {
                    width: 60px;
                    flex-shrink: 0;

                    margin-left: 18px;
                    margin-right: 0;
                }
                &-text {
                    text-align: right;
                    margin: 0;
                }
            }
        }
        &.icongroup-icon-rounded {
            .icongroup-item {
                &-icon {
                    border-radius: 999px;
                    overflow: hidden;
                }
            }
        }
        &.icongroup-item-square {
            .icongroup-item {
                display: flex;
                align-items: center;
                justify-content: center;

                background-color: $colorGrayLight;
                padding: 30px 20px;
                margin-top: 10px;
                margin-bottom: 10px;

                max-width: 100%;
            }
            &.icongroup-columns-6,
            &.icongroup-columns-5,
            &.icongroup-columns-4,
            &.icongroup-columns-3,
            &.icongroup-columns-2 {
                .icongroup-item {
                    width: 100%;
                    @include breakpoint(xs) {
                        width: calc(50% - 10px);
                        max-width: calc(50% - 10px);
                    } 
                }  
            }
            &.icongroup-columns-6 {
                .icongroup-item { 
                    @include breakpoint(md) {
                        width: calc(33% - 10px);
                        max-width: calc(33% - 10px);
                    } 
                    @include breakpoint(xl) {
                        width: calc(16% - 10px);
                        max-width: calc(16% - 10px);
                    }  
                }  
            }
            &.icongroup-columns-5 {
                .icongroup-item {
                    @include breakpoint(md) {
                        width: calc(33% - 10px);
                        max-width: calc(33% - 10px);
                    } 
                    @include breakpoint(xl) {
                        width: calc(20% - 10px);
                        max-width: calc(20% - 10px);
                    }  
                }  
            }
            &.icongroup-columns-4 {
                .icongroup-item { 
                    @include breakpoint(lg) {
                        width: calc(25% - 10px);
                        max-width: calc(25% - 10px);
                    }  
                }  
            }
            &.icongroup-columns-3 {
                .icongroup-item { 
                    @include breakpoint(lg) {
                        width: calc(33% - 10px);
                        max-width: calc(33% - 10px);
                    }  
                }  
            }
            &.icongroup-columns-1 {
                .icongroup-item {
                    width: 100%;
                }
            }
        }
        &.icongroup-small {
            .icongroup-item-icon {
                max-width: 75px !important;
            }

            .icongroup-item-text {
                &-header {
                    font-size: calc(1.09375 * #{$fontPrimarySize});
                }
            }
        }
    }

    .container--wide {
        @include breakpoint(xl) {
            padding: 0 44px;
        }
        @include breakpoint(xl2) {
            padding: 0 60px;
        }
        .icongroup {
            padding-left: 20px;
            padding-right: 20px;
            &-item {
                @include breakpoint(lg) {
                    font-size: calc(1.375 * #{$fontPrimarySize});
                }
                @include breakpoint(xl) {
                    font-size: calc(1.5 * #{$fontPrimarySize});
                }
                &-icon {
                    width: 100%;
                    max-width: 180px;
                }
            }
        }
    }

    & {
        &--primary {
            .frame-header {
                color: $colorPrimary;
            }

            .icongroup-item-text {
                color: $colorPrimary !important;
            }
        }
    }

    & {
        &--primary-dark {
            .icongroup-item-text {
                color: $colorPrimaryDark;
            }
        }
    }

    & {
        &--secondary {
            .icongroup-item-text {
                color: $colorSecondary;
            }
        }
    }
}

.variant--rounded {
    .frame-type-icon_group {
       .icongroup.icongroup-item-square {
            .icongroup-item {
                border-radius: 15px;
            }
        }
    }
}

.variant--oblong .frame-type-icon_group, .variant--oblong.frame-type-icon_group {
        .icongroup-item-icon {
            border-radius: 50%;
            overflow: hidden;
            aspect-ratio: 1/1;
            
            img {
                object-fit: cover;
            }
        }
       .icongroup.icongroup-item-square {
            .icongroup-item {
                border-radius: 27px;
            }
            .icongroup-item-icon {
                border-radius: 0px;
                aspect-ratio: auto;
            }
        }
}