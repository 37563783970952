* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;

  font-family: $fontPrimary;
  font-size: unset;
  font-weight: unset;

  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out, border-radius .5s ease-in-out;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 220px;
}

body {
  position: relative;
}

img {
  max-width: 100%;
}

a {
  display: inline-block;
}

main {
  display: block;
}

hr {
  border: none;
  border-top: 1px solid;
}
