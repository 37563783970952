// Style de base des carousels

// Variables de couleurs pour définir les couleur des éléments ?
// $colorSlideContent : ;
// $colorSlideBg : ;
// $colorSlideDots: ;
// $colorSlideBtnBg: ;
// $colorSlideBtnIcon: ;

.carousel {
    overflow: hidden;
    .flickity-slider {
        position: relative;

        transform: none !important;
    }
    .slide {
        width: 100%;
        height: 100%;

        transform: none !important;
        &.is-selected {
            position: relative!important;

            opacity: 1;
      
            pointer-events: all;
      
            animation: fadeIn ease 0.5s;
        }
      
        &:not(.is-selected) {
            position: absolute;

            opacity: 0;
        
            pointer-events: none;
        
            animation: fadeOut ease 0.5s;
        }
    }
    .slide-container {
        color: $colorSlideText;
        background-color: $colorSlideBg;

        box-sizing: border-box;

        .btn {
            background-color: $white;
            color: $colorSlideBg;
        }
    }
    .slide-image {
        overflow: hidden;
    }
    .flickity-prev-next-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        width: 36px;
        height: 36px;

        opacity: 1;

        background-color: $colorSlideBtnBg;
        color: $colorSlideBtnIcon;

        transition: opacity 200ms ease-in;

        &::before,
        &::after {
            content: '';
            position: absolute;

            width: 38px;
            height: 700px;

            background-color: rgba(0,0,0,0);

            @include breakpoint(lg) {
                width: 60px;
            }
        }
        &::before {
            bottom: calc(50% - 18px);
        }
        &::after {
            top: calc(50% - 18px);
        }
        &.previous {
            left: 0;
    
            border-radius: 0px;
            &::before,
            &::after {
                left: 0;
            }
        }
        &.next {
            right: 0;
    
            border-radius: 0px;
            &::before,
            &::after {
                right: 0;
            }
        }
        &:hover {
            background-color: $colorSlideBtnBg;
            color: $colorSlideBtnIcon;

            opacity: 0.9;

            transition: all 200ms ease-in;
        }
        .flickity-button-icon {
            position: static;

            width: 12px;
            height: 24px;
        }
    }
    .flickity-page-dots {
        z-index: 2;
        bottom: 25px;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        .flickity-page-dot,
        .dot {
            margin: 0;

            background: unset;
            background-color: $colorSlideDots;

            border-radius: 0;

            opacity: 1;

            transition: width 0.3s ease-in-out;
            &.is-selected {
                width: 50px;

                opacity: 0.5;
            }
            & + .flickity-page-dot,
            & + .dot {
                margin-left: 8px;
            }
        }
    }
    // Particularités selon le style du carousel :::::::::::::::::::::::::::::::
    // Styles des contrôles :::::::::::::::::::::::::
    // BOTTOM LEFT
    &-controls--bottom-left {
        .flickity-page-dots {
            justify-content: flex-start;
        }
        .flickity-prev-next-button {
            top: calc(100% - 56px);
        }
    }
    // BOTTOM RIGHT
    &-controls--bottom-right {
        .flickity-page-dots {
            justify-content: flex-end;
        }
        .flickity-prev-next-button {
            top: calc(100% - 31px);

            @include breakpoint(lg) {
                top: calc(100% - 56px);
            }
        }
    }
    // BOTTOM CENTER
    &-controls--bottom-center {
        .flickity-page-dots {
            justify-content: center;
        }
        .flickity-prev-next-button {
            top: calc(100% - 56px);
        }
    }
    // MI-HAUTEUR
    &-controls--middle {
        .flickity-page-dots {
            justify-content: center;
        }
    }
    // Carousel côte/côte
    &--half-page {
        &.carousel-controls--middle {
            .slide-container {
                padding-left: 45px;
                padding-right: 45px;

                @include breakpoint(sm) {
                    padding-left: 48px;
                }
                @include breakpoint(lg) {
                    padding-left: 60px;
                }
            }
            .flickity-page-dots {
                justify-content: left;
                bottom: 40px;
                left: 45px;
    
                width: calc(100% - 90px);
    
                text-align: left;
    
                @include breakpoint(sm) {
                    left: 48px;
                    width: calc(52% - 78px);
                }
                @include breakpoint(lg) {
                    left: 60px;
                    width: calc(52% - 90px);
                }
                @include breakpoint(xl) {
                    left: unset;
                    right: calc(55% + 30px);
    
                    width: calc(45% - 90px);
                    max-width: 483px;
                }
            }
        }
        &.carousel-controls--bottom-left,
        &.carousel-controls--bottom-right,
        &.carousel-controls--bottom-center {
            .flickity-prev-next-button {
                top: calc(100% - 48px);
                &.previous {
                    left: 30px;
                    /* @include breakpoint(sm) {
                        left: 48px;
                    } */
                    @include breakpoint(lg) {
                        left: 60px;
                    }
                }
                &.next {
                    right: 30px;
                    @include breakpoint(sm) {
                        right: calc(48% + 30px);
                    }
                    @include breakpoint(lg) {
                        right: calc(55% + 30px);
                    }
                }
            }
        }
        .slide,
        .mini-slider-section__slide,
        .slider-section__slide {
            display: flex;
            flex-direction: column-reverse;

            @include breakpoint(sm) {
                flex-direction: row;
                align-items: stretch;
            }
        }
        .slide-container {
            width: 100%;
            height: 100%;

            padding: 36px 30px 85px 30px;
            @include breakpoint(sm) {
                width: 52%;
                // padding: 50px 30px 75px 60px;
            }
            @include breakpoint(lg) {
                width: 45%;
                min-height: 420px;

                padding: 50px 30px 85px 60px;
            }
            @include breakpoint(xl) {
                min-height: 500px;
            }
            & >  * {
                @include breakpoint(lg) {
                    max-width: 483px;
                    margin-left: auto;
                }
            }
        }
        .slide-image {
            flex: 1 0 auto;

            position: relative;
            z-index: 0;

            height: 300px;
            width: 100%;
            @include breakpoint(sm) {
                flex: 1;
                height: auto;
            }
            @include breakpoint(lg) {
                min-height: 420px;
            }
            @include breakpoint(xl) {
                min-height: 500px;
            }

            img {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;

                object-fit: cover;
            }
        }
        .flickity-prev-next-button {
            top: calc(50% - 48px);
            @include breakpoint(sm) {
                top: 50%;
            }
            /* &.next {
                background-color: $colorSlideBtnIcon;
                color: $colorSlideBtnBg;
            } */
        }
        .flickity-page-dots {
            bottom: 40px;
            left: 80px;

            width: calc(100% - 160px);

            text-align: left;

            @include breakpoint(sm) {
                left: 80px;
                width: calc(52% - 155px);
            }
            @include breakpoint(lg) {
                left: unset;
                right: calc(55% + 75px);

                width: calc(45% - 180px);
                // max-width: 326px;
                max-width: 388px;
            }
        }
    }
    // CARROUSEL avec encart
    &--panel {
        .slide {
            padding: 1px 0;
            display: flex;
            align-items: flex-end;

            @include breakpoint(lg) {
                padding-bottom: 50px;
            }
        }
        .slide-container {
            position: relative;
            z-index: 1;
            flex-shrink: 0;

            width: calc(100% - 72px);
            max-width: 400px;
            padding: 22px 26px;
            margin: 60px auto 0px 36px;
            @include breakpoint(sm) {
                max-width: 60%;
                padding: 36px 42px;
            }
            @include breakpoint(lg) {
                max-width: 610px;

                padding: 50px 60px;
                margin-left: 70px;
                margin-top: 60px;
                margin-bottom: 0px;
            }
            @include breakpoint(xl) {
                margin-top: 70px;
                margin-bottom: 0px;
            }
        }
        .slide-image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            width: 100%;
            height: calc(100% - 130px);

            background-color: $black;

            img {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;

                object-fit: cover;
                opacity: 0.6;
            }
        }
        .slide-mockups {
            position: relative;
            z-index: 1;
            display: none;

            background-color: rgba(0,0,0,0);

            padding-right: 40px;
            margin-top: 70px;

            width: 236px;
            @include breakpoint(sm) {
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                flex-shrink: 1;
            }
            @include breakpoint(lg) {
                margin-top: 60px;
                margin-left: 0;
                width: auto;
            }
            @include breakpoint(xl) {
                margin-top: 70px;
                padding-right: 10px;
                margin-left: 30px;
            }
            @include breakpoint(3xl) {
                padding-right: 70px;
                margin-left: 90px;

                margin-right: 70px;
            }
        }
        .slide-mockup {
            &--desktop {
                display: none;
                @include breakpoint(lg) {
                    display: block;

                    height: 550px;
                    width: auto;
                    max-width: 1060px;
                }
                @include breakpoint(3xl) {
                    width: 100%;
                    height: auto;
                }
            }
            &--mobile {
                position: absolute;
                bottom: 0;
                left: 0;

                width: 236px;
                @include breakpoint(lg) {
                    bottom: -50px;
                }
                @include breakpoint(3xl) {
                    right: 12px;
                    left: unset;

                    width: 252px;
                }
            }
        }
        &__slide {
            padding: 60px 0;
        }
        .flickity-prev-next-button {
            background-color: $colorSlideBtnIcon;
            color: $colorSlideBtnBg;
        }
        .flickity-page-dots {
            left: 48px;
            bottom: 25px;

            width: calc(100% - 96px);

            @include breakpoint(lg) {
                left: 10%;
                bottom: 48px;

                width: 80%;
            }
            .flickity-page-dot,
            .dot {
                background-color: $colorSlideBtnIcon;
            }
        }
    }
    // CARROUSEL Pleine largeur
    &--full {
        .slide {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding: 1px 0;

            min-height: 400px;

            @include breakpoint(lg) {
                min-height: 550px;
            }
        }
        .slide-container {
            width: 80%;
            max-width: 800px;
            height: calc(100% - 80px);
      
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
      
            position: relative;
            z-index: 1;
      
            margin: 20px auto 60px auto;
      
            text-align: center;

            background-color: rgba(0,0,0,0);

            @include breakpoint(md) {
                // margin-top: 90px;
            }
            @include breakpoint(xl) {
                // margin-top: 130px;
            }
        }
        .slide-image {
            width: 100%;
            height: 100%;
      
            position: absolute;
            top: 0;
            left: 0;

            background-color: $colorSlideBg;
      
            img {
              width: 100%;
              height: 100%;
      
              opacity: 0.4;
      
              object-fit: cover;
            }
        }
        .flickity-page-dots {
            bottom: 40px;
            left: 48px;

            text-align: center;
            width: calc(100% - 96px);

            @include breakpoint(lg) {
                left: 10%;

                width: 80%;
            }
        }
    }
    // CARROUSEL full-height
    &--full-height {
        .slide,
        .mini-slider-section__slide,
        .slider-section__slide {
            display: flex;
            flex-direction: column-reverse;

            @include breakpoint(sm) {
                flex-direction: row;
                align-items: stretch;
            }
        }
        .slide-container {
            width: 100%;
            // height: 100%;

            padding: 30px 38px;
            padding-bottom: 120px;
            @include breakpoint(sm) {
                width: 60%;
                height: 100%;
                padding: 38px 48px 150px 38px;
            }
            @include breakpoint(lg) {
                padding: 50px 60px 150px 60px;

                width: 30%;
                min-width: 580px;
            }
        }
        .slide-image {
            flex: 1 0 auto;
            height: 300px;

            position: relative;
            z-index: 0;

            @include breakpoint(sm) {
                flex: 1;
                height: auto;
                width: 40%;
            }
            @include breakpoint(lg) {
                width: 70%;
            }

            img {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;

                object-fit: cover;
            }
        }
        .flickity-prev-next-button {
            top: unset;
            bottom: 15px;

            background-color: $white;
            color: $black;
            @include breakpoint(lg) {
                bottom: 35px;
            }
            &::before,
            &::after {
                display: none;
            }
            &.previous {
                left: 38px;
                @include breakpoint(sm) {
                    left: 48px;
                }
                @include breakpoint(lg) {
                    left: 60px;
                }
            }
            &.next {
                right: 38px;
                @include breakpoint(sm) {
                    right: calc(40% + 48px);
                }
                @include breakpoint(lg) {
                    right: calc(100% - 520px);
                }
            }
        }
        .flickity-page-dots {
            bottom: 85px;
            left: 38px;

            width: calc(100% - 76px);

            text-align: left;
            @include breakpoint(sm) {
                left: 48px;

                width: calc(60% - 48px);
            }
            @include breakpoint(lg) {
                left: 60px;
                bottom: 105px;

                width: calc(30% - 60px);
            }
        }
    }
    &--arrows-center {
        .slide,
        .mini-slider-section__slide,
        .slider-section__slide {
            padding: 1px 0;
            display: flex;
            align-items: center;
        }
        .slide-container {
            position: relative;
            z-index: 1;

            width: calc(100% - 96px);
            max-width: 400px;
            padding: 22px 26px;
            margin: 60px auto 60px 48px;
            @include breakpoint(sm) {
                max-width: 60%;
                padding: 36px 42px;
            }
            @include breakpoint(lg) {
                max-width: 670px;

                padding: 50px 60px;
                margin-left: 10%;
                margin-top: 80px;
                margin-bottom: 80px;
            }
            @include breakpoint(xl) {
                margin-top: 100px;
                margin-bottom: 100px;
            }
        }
        .slide-image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;

                object-fit: cover;
            }
        }
        &__slide {
            padding: 60px 0;
        }
        .flickity-prev-next-button {
            background-color: $colorSlideBtnIcon;
            color: $colorSlideBtnBg;
        }
        .flickity-page-dots {
            left: 40px;
            bottom: 25px;
            
            text-align: right;
            width: calc(100% - 96px);

            @include breakpoint(lg) {
                left: 5%;
                bottom: 48px;

                width: 90%;
            }
            .flickity-page-dot,
            .dot {
                background-color: $colorSlideBtnIcon;
            }
        }
    }
    &--arrows-bottom {
        .slide,
        .mini-slider-section__slide,
        .slider-section__slide {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            
            padding: 1px 0;
        }
        .slide-container {
            position: relative;
            z-index: 1;

            width: calc(100% - 96px);
            max-width: 400px;
            padding: 22px 26px;
            margin: 60px auto 60px 48px;
            @include breakpoint(sm) {
                max-width: 60%;

                padding: 36px 42px;
            }
            @include breakpoint(lg) {
                max-width: 560px;

                padding: 50px 60px;
                margin: 80px auto 80px 65px;
            }
            @include breakpoint(xl) {
                max-width: 610px;
                margin-top: 100px;
                margin-bottom: 100px;
            }
        }
        .slide-image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;

                object-fit: cover;
            }
        }
        .flickity-prev-next-button {
            top: unset;
            bottom: 0px;

            background-color: $colorSlideBtnIcon;
            color: $colorSlideBtnBg;
        }
        .flickity-page-dots {
            left: 50px;
            width: calc(100% - 100px);
            text-align: right;
            .flickity-page-dot,
            .dot {
                background-color: $colorSlideBtnIcon;
            }
        }
    }
    &--half-page-right,
    &--reviews-arrows-inside {
        &.carousel-controls--middle {
            .flickity-page-dots {
                left: 30px;
                bottom: 40px;
    
                width: calc(100% - 60px);
                text-align: left;
                @include breakpoint(sm) {
                    left: calc(42% + 40px);
                    bottom: 40px;
    
                    width: calc(58% - 80px);
                }
                @include breakpoint(lg) {
                    left: calc(40% + 60px);
                    bottom: 64px;
    
                    width: calc(60% - 120px);
                }
            }

        }
        &.carousel-controls--bottom-left {
            .flickity-prev-next-button {
                top: unset;
                bottom: 11px;
                @include breakpoint(sm) {
                    bottom: 13px;
                }
                @include breakpoint(lg) {
                    bottom: 40px;
                }
                &.previous {
                    left: 30px;
                    @include breakpoint(sm) {
                        // right: 90px;
                        left: calc(40% + 60px);
                    }
                    @include breakpoint(lg) {
                        // right: 110px;
                        left: calc(40% + 60px);
                    }
                }
                &.next {
                    right: unset;
                    left: 80px;
                    @include breakpoint(sm) {
                        // right: 40px;
                        left: calc(40% + 110px);
                    }
                    @include breakpoint(lg) {
                       // right: 60px; 
                       left: calc(40% + 110px);
                    }
                }
            }
            .flickity-page-dots {
                left: 130px;
                bottom: 40px;
    
                width: calc(100% - 160px);
                text-align: left;
                @include breakpoint(sm) {
                    left: calc(42% + 143px);
                    bottom: 40px;
    
                    width: calc(58% - 180px);
                }
                @include breakpoint(lg) {
                    left: calc(40% + 164px);
                    bottom: 64px;
    
                    width: calc(60% - 230px);
                }
                .flickity-page-dot,
                .dot {
                    background-color: $colorGray;
                }
            }
        }
        &.carousel-controls--bottom-center {
            .flickity-prev-next-button {
                top: unset;
                bottom: 11px;
                @include breakpoint(sm) {
                    bottom: 13px;
                }
                @include breakpoint(lg) {
                    bottom: 40px;
                }
                &.previous {
                    left: 30px;
                    @include breakpoint(sm) {
                        // right: 90px;
                        left: calc(40% + 60px);
                    }
                    @include breakpoint(lg) {
                        // right: 110px;
                        left: calc(40% + 60px);
                    }
                }
                &.next {
                    right: 30px;
                    @include breakpoint(sm) {
                        right: 40px;
                    }
                    @include breakpoint(lg) {
                       right: 60px; 
                    }
                }
            }
            .flickity-page-dots {
                left: 80px;
                bottom: 40px;
    
                width: calc(100% - 160px);
                text-align: left;
                @include breakpoint(sm) {
                    left: calc(42% + 90px);
                    bottom: 40px;
    
                    width: calc(58% - 180px);
                }
                @include breakpoint(lg) {
                    left: calc(40% + 114px);
                    bottom: 64px;
    
                    width: calc(60% - 230px);
                }
                .flickity-page-dot,
                .dot {
                    background-color: $colorGray;
                }
            }
            
        }
        &.carousel-controls--bottom-right {
            .flickity-prev-next-button {
                top: unset;
                bottom: 11px;
                @include breakpoint(sm) {
                    bottom: 13px;
                }
                @include breakpoint(lg) {
                    bottom: 40px;
                }
                &.previous {
                    left: unset;
                    right: 80px;
                    @include breakpoint(sm) {
                        right: 90px;
                    }
                    @include breakpoint(lg) {
                        right: 110px;
                    }
                }
                &.next {
                    right: 30px;
                    @include breakpoint(sm) {
                        right: 40px;
                    }
                    @include breakpoint(lg) {
                       right: 60px; 
                    }
                }
            }
            .flickity-page-dots {
                left: 30px;
                bottom: 40px;
    
                width: calc(100% - 160px);
                text-align: left;
                @include breakpoint(sm) {
                    left: calc(42% + 40px);
                    bottom: 40px;
    
                    width: calc(58% - 180px);
                }
                @include breakpoint(lg) {
                    left: calc(40% + 60px);
                    bottom: 64px;
    
                    width: calc(60% - 230px);
                }
                .flickity-page-dot,
                .dot {
                    background-color: $colorGray;
                }
            }
        }
        .slide {
            display: flex;
            flex-direction: column-reverse;

            @include breakpoint(sm) {
                flex-direction: row-reverse;
                align-items: stretch;
            }
        }
        .slide-container {
            width: 100%;
            height: 100%;

            padding: 36px 30px 80px 30px;

            background-color: $colorGrayLight;
            color: $black;
            @include breakpoint(sm) {
                width: 58%;
                min-height: 420px;
                padding: 36px 40px 80px 40px;
            }
            @include breakpoint(lg) {
                width: 60%;
                // min-height: 420px;
                padding: 50px 60px 110px 60px;
            }
            @include breakpoint(xl) {
                // min-height: 500px;
            }
        }
        .slide-image {
            flex: 1 0 auto;

            position: relative;
            z-index: 0;

            height: 300px;
            width: 100%;
            @include breakpoint(sm) {
                flex: 1;
                height: auto;
            }
            /* @include breakpoint(lg) {
                min-height: 420px;
            }
            @include breakpoint(xl) {
                min-height: 500px;
            } */

            img {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;

                object-fit: cover;
            }
        }
        .flickity-page-dots {
            .flickity-page-dot,
            .dot {
                background-color: $colorGray;
            }
        }
        .flickity-prev-next-button {
            background-color: $black;
            color: $white;
        }
    }
    //
    &--reviews-arrows-outside {
        overflow: visible;
        .slide,
        .mini-slider-section__slide,
        .slider-section__slide {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;

            background-color: $colorGrayLight;

            padding: 30px;

            @include breakpoint(sm) {
                flex-direction: row-reverse;
                align-items: stretch;

                padding: 35px;
            }
            @include breakpoint(lg) {
                padding: 50px 60px;
            }
        }
        .slide-container {
            width: 100%;
            height: 100%;

            padding: 0px 0px 40px 0px;

            background-color: $colorGrayLight;
            color: $black;
            @include breakpoint(sm) {
                width: calc(100% - 275px);
                padding: 10px 10px 50px 30px;
            }
            @include breakpoint(lg) {
                width: calc(100% - 275px);
                padding: 10px 15px 80px 45px;
            }
        }
        .slide-image {
            // flex: 1 0 auto;

            position: relative;
            z-index: 0;

            margin-bottom: 20px;

            height: 275px;
            width: 275px;
            @include breakpoint(sm) {
                margin: 0;
            }

            img {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;

                object-fit: cover;
            }
        }
        .flickity-prev-next-button {
            top: unset;
            bottom: 11px;

            background-color: $black;
            color: $white;

            @include breakpoint(sm) {
                top: 50%;
                bottom: unset;
            }
            &.previous {
                left: 30px;
                @include breakpoint(sm) {
                    left: -36px;
                }
            }
            &.next {
                right: unset;
                left: 86px;
                @include breakpoint(sm) {
                    right: -36px;
                    left: unset;
                }
            }
        }
        .flickity-page-dots {
            left: unset;
            right: 30px;
            bottom: 40px;

            width: calc(100% - 170px);
            text-align: right;
            @include breakpoint(sm) {
                right: 55px;
                bottom: 55px;
            }
            @include breakpoint(lg) {
                right: 45px;
                bottom: 55px;

                width: calc(60% - 60px);
            }
            .flickity-page-dot,
            .dot {
                background-color: $colorGray;
            }
        }
    }
    &--arrows-left {
        overflow: visible;
        @include breakpoint(lg) {
            margin-left: 80px;
        }
        .slide,
        .mini-slider-section__slide,
        .slider-section__slide {
            padding: 1px 0 200px 0;
            @include breakpoint(sm) {
                padding-bottom: 120px;
            }
            @include breakpoint(lg) {
                padding-bottom: 90px;
            }
        }
        .slide-container {
            position: absolute;
            bottom: 0px;
            z-index: 1;

            width: calc(100% - 48px);
            max-width: calc(100% - 48px);
            padding: 36px 32px 52px 32px;
            margin: 0px auto 0px 48px;
            @include breakpoint(sm) {
                width: 68%;
                max-width: 650px;
            }
            @include breakpoint(lg) {
                max-width: 670px;

                padding: 50px 60px 100px 60px;
                margin-left: 10%;
                //margin-top: 80px;
                //margin-bottom: 80px;
            }
            @include breakpoint(xl) {
                //margin-top: 100px;
                //margin-bottom: 100px;
            }
        }
        .slide-image {
            position: relative;
            z-index: 0;

            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;

                object-fit: cover;
            }
        }
        &__slide {
            padding: 60px 0;
        }
        .flickity-prev-next-button {
            background-color: $white;
            color: $black;
            &.next {
                display: none;
            }
        }
        .flickity-page-dots {
            left: 80px;
            bottom: 25px;
            
            text-align: left;
            width: calc(100% - 72px);
            @include breakpoint(sm) {
                left: 90px;

                width: calc(66% - 84px);
            }
            @include breakpoint(lg) {
                left: calc(10% + 60px);
                bottom: 48px;

                width: 550px;
            }
            .flickity-page-dot,
            .dot {
                background-color: $white;
            }
        }
    }
}

.bg--gray {
    .carousel--half-page-right {
        .slide-container {
            background-color: $white;
        }
    }
}

// Font not imposed
.frame-bg {
    &--white {
        .carousel {
            .slide-container {
                background-color: $white;
    
                * {
                    color: $black;
                }
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $white;
                }
            }

            .flickity-button {
                background-color: $white;
                color: $black
            }

            &.carousel--half-page {
                .flickity-button.previous {
                    background-color: $black;
                    color: $white;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $black;
                    }
                }
            }

            &.carousel--full {
                .slide-container {
                    background-color: unset;
                }
                
                .slide-image {
                    background-color: $white;
                }

                .flickity-button {
                    background-color: $black;
                    color: $white;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $black;
                    }
                }
            }
        }
    }

    &--black {
        .carousel {
            .slide-container {
                background-color: $black;
    
                * {
                    color: $white;
                }
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $black;
                }
            }

            .flickity-button {
                background-color: $black;
                color: $white
            }

            &.carousel--half-page {
                .flickity-button.previous {
                    background-color: $white;
                    color: $black;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $white;
                    }
                }
            }

            &.carousel--full {
                .slide-container {
                    background-color: unset;
                }
                
                .slide-image {
                    background-color: $black;
                }

                .flickity-button {
                    background-color: $white;
                    color: $black;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $white;
                    }
                }
            }
        }
    }

    &--secondary {
        .carousel {
            .slide-container {
                background-color: $colorSecondary;
    
                * {
                    color: $black;
                }
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $black;
                }
            }

            .flickity-button {
                background-color: $colorSecondary;
                color: $black
            }

            &.carousel--half-page {
                .flickity-button.previous {
                    background-color: $black;
                    color: $white;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $black;
                    }
                }
            }

            &.carousel--full {
                .slide-container {
                    background-color: unset;
                }
                
                .slide-image {
                    background-color: $colorSecondary;
                }

                .flickity-button {
                    background-color: $black;
                    color: $white;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $black;
                    }
                }
            }
        }
    }
}


.frame-font {
    &--primary {
        .carousel {
            .slide-container {
                @for $i from 1 through 6 {
                    h#{$i} {
                        color: $colorPrimary;
                    }
                }
            }
        }
    }

    &--primary-light {
        .carousel {
            .slide-container {
                @for $i from 1 through 6 {
                    h#{$i} {
                        color: $colorPrimaryLight;
                    }
                }
            }
        }
    }

    &--primary-dark {
        .carousel {
            .slide-container {
                @for $i from 1 through 6 {
                    h#{$i} {
                        color: $colorPrimaryDark;
                    }
                }
            }
        }
    }

    &--secondary {
        .carousel {
            .slide-container {
                @for $i from 1 through 6 {
                    h#{$i} {
                        color: $colorSecondary;
                    }
                }
            }
        }
    }

    &--white {
        .carousel {
            .slide-container {
                @for $i from 1 through 6 {
                    h#{$i} {
                        color: $white;
                    }
                }
            }
        }
    }

    &--black {
        .carousel {
            .slide-container {
                @for $i from 1 through 6 {
                    h#{$i} {
                        color: $black;
                    }
                }
            }
        }
    }
}

// Font imposed
.frame-bg {
    &--primary {
        .carousel {
            .slide-container {
                background-color: $colorPrimary;

                * {
                    color: $textColor;
                }
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $colorPrimary;
                }
            }

            .flickity-button {
                background-color: $colorPrimary;
                color: $textColor;
            }

            &.carousel--half-page {
                .flickity-button.previous {
                    background-color: $textColor;
                    color: $colorPrimary;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $textColor;
                    }
                }
            }

            &.carousel--full {
                .slide-container {
                    background-color: unset;
                }
                
                .slide-image {
                    background-color: $colorPrimary;
                }

                .flickity-button {
                    background-color: $textColor;
                    color: $colorPrimary;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $textColor;
                    }
                }
            }
        }
    }

    &--primary-light {
        .carousel {
            .slide-container {
                background-color: $colorPrimaryLight;

                * {
                    color: $black !important;
                }
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $colorPrimaryLight;
                }
            }

            .flickity-button {
                background-color: $colorPrimaryLight;
                color: $black;
            }

            &.carousel--half-page {
                .flickity-button.previous {
                    background-color: $black;
                    color: $colorPrimaryLight;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $black;
                    }
                }
            }

            &.carousel--full {
                .slide-container {
                    background-color: unset;
                }
                
                .slide-image {
                    background-color: $colorPrimaryLight;
                }

                .flickity-button {
                    background-color: $black;
                    color: $colorPrimaryLight;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $black;
                    }
                }
            }          
        }
    }

    &--primary-dark {
        .carousel {
            .slide-container {
                background-color: $colorPrimaryDark;

                * {
                    color: $white !important;
                }
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $colorPrimaryDark;
                }
            }

            .flickity-button {
                background-color: $colorPrimaryDark;
                color: $white;
            }

            &.carousel--half-page {
                .flickity-button.previous {
                    background-color: $white;
                    color: $colorPrimaryDark;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $white;
                    }
                }
            }

            &.carousel--full {
                .slide-container {
                    background-color: unset;
                }
                
                .slide-image {
                    background-color: $colorPrimaryDark;
                }

                .flickity-button {
                    background-color: $white;
                    color: $colorPrimaryDark;
                }

                .flickity-page-dots {
                    .flickity-page-dot,
                    .dot {
                        background-color: $white;
                    }
                }
            }
        }
    }
}

.frame-arrow {
    &--primary {
        .carousel {
            .flickity-prev-next-button {
                background-color: $colorPrimary;
                color: $white;
            }
            
            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $colorPrimary;
                }
            }
        }
    }

    &--primary-light {
        .carousel {
            .flickity-prev-next-button {
                background-color: $colorPrimaryLight;
                color: $white;
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $colorPrimaryLight;
                }
            }
        }
    }

    &--primary-dark {
        .carousel {
            .flickity-prev-next-button {
                background-color: $colorPrimaryDark;
                color: $white;
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $colorPrimaryDark;
                }
            }
        }
    }

    &--secondary {
        .carousel {
            .flickity-prev-next-button {
                background-color: $colorSecondary;
                color: $white;
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $colorSecondary;
                }
            }
        }
    }

    &--white {
        .carousel {
            .flickity-prev-next-button {
                background-color: $white;
                color: $black;
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $white;
                }
            }
        }
    }

    &--black {
        .carousel {
            .flickity-prev-next-button {
                background-color: $black;
                color: $white;
            }

            .flickity-page-dots {
                .flickity-page-dot,
                .dot {
                    background-color: $black;
                }
            }
        }
    }
}

// // Variations de couleurs
// $colors: 
//     "primary" $colorPrimary, 
//     "secondary" $colorSecondary,
//     "primary-dark" $colorPrimaryDark;

// @each $element, $color in $colors {
//     .frame--#{$element} {
//         .carousel {
//             .slide-container {
//                 background-color: $color;
//             }
//             &--full-height {
//                 .flickity-page-dots {
//                     .dot {
//                         background-color: $white;
//                     }
//                 }
//             }
//             &--half-page {
//                 .flickity-page-dots {
//                     .dot {
//                         background-color: $white;
//                     }
//                 }
//             }
//         }
//     }
// }

.variant--rounded {
    .container:not(.container--wide) {
        .carousel {
            border-radius: 18px;
        }
    }
    .carousel {
        .flickity-prev-next-button {
            &.previous {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
            &.next {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }
        }
        .flickity-page-dots  {
            .flickity-page-dot,
            .dot {
                border-radius: 20px;
            }
        }
    }
    .carousel--full-height {
        .flickity-prev-next-button {
            border-radius: 6px;
        }
    }
    .carousel--arrows-bottom,
    .carousel--arrows-center {
        .slide-container {
            border-radius: 10px;
        }
    }
    .carousel--reviews-arrows-outside {
        .slide {
            border-radius: 20px;
        }
        .slide-image {
            border-radius: 20px;
        }
        .flickity-prev-next-button {
            &.previous,
            &.next {
                border-radius: 6px;
            }
        }
    }
}

.variant--oblong {
    .container:not(.container--wide) {
        .carousel {
            border-radius: 27px;
        }
    }
    .carousel {
        .flickity-prev-next-button {
            &.previous {
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
            }
            &.next {
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
            }
        }
        .flickity-page-dots  {
            .flickity-page-dot,
            .dot {
                border-radius: 20px;
            }
        }
    }
    .carousel--full-height {
        .flickity-prev-next-button {
            border-radius: 50%;
        }
    }
    .carousel--arrows-bottom,
    .carousel--arrows-center {
        .slide-container {
            border-radius: 27px;
        }
    }
    .carousel--reviews-arrows-outside {
        .slide {
            border-radius: 27px;
        }
        .slide-image {
            border-radius: 999px;
        }
        .flickity-prev-next-button {
            &.previous,
            &.next {
                border-radius: 50%;
            }
        }
    }
}