
.list {
    &--primary {
        li {
            &::marker {
                color: $colorPrimary;
            }
        }
    }
    &--primary-dark {
        li {
            &::marker {
                color: $colorPrimaryDark;
            }
        }
    }
    &--secondary {
        li {
            &::marker {
                color: $colorSecondary;
            }
        }
    }
    &--disc {
        list-style-type: disc;
    }
    &--circle {
        list-style-type: circle;
    }
    &--square {
        list-style-type: square;
    }
}

