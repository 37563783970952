.bg,
.frame {
    &--primary {
        background-color: $colorPrimary;

        &-light {
            background-color: $colorPrimaryLight;
        }

        &-dark {
            background-color: $colorPrimaryDark;
        }
    }
    &--secondary {
        background-color: $colorSecondary;
    }

    &--white {
        background-color: $white;
    }

    &--black {
        background-color: $black;
    }
    &--gray {
        background-color: $colorGrayLight;
    }
}