form {
    font-size: 1.6rem;

    @include breakpoint(md) {
        font-size: 1.8rem;
    }
    @include breakpoint(xl) {
        font-size: 2rem;
    }
    input {
        &[type="text"],
        &[type="date"],
        &[type="email"],
        &[type="month"],
        &[type="number"],
        &[type="password"],
        &[type="tel"],
        &[type="time"],
        &[type="url"],
        &[type="file"] {
            width: 100%;
            box-sizing: border-box;

            padding: 8px 12px;

            border: 1px solid $colorInputBorder;
            background-color: $colorInputBg;

            &::-webkit-input-placeholder {
                font-style: italic;
            }
            &::-moz-placeholder {
                font-style: italic;
            }
            &:-ms-input-placeholder {
                font-style: italic;
            }
            &:-moz-placeholder {
                font-style: italic;
            }
            &:invalid {
                box-shadow: 0 0 0 2px solid rgba(224,31,27,0.8);
            }
        }
    }
    select {
        width: 100%;
        box-sizing: border-box;

        padding: 8px 12px;

        border: 1px solid $colorInputBorder;
        background-color: $colorInputBg;
    }
    textarea {
        width: 100%;
        box-sizing: border-box;

        padding: 8px 12px;

        border: 1px solid $colorInputBorder;
        background-color: $colorInputBg;
        &::-webkit-input-placeholder {
            font-style: italic;
        }
        &::-moz-placeholder {
            font-style: italic;
        }
        &:-ms-input-placeholder {
            font-style: italic;
        }
        &:-moz-placeholder {
            font-style: italic;
        }
    }
    .form-group {
        margin-bottom: 20px;
        .control-label {
            display: inline-block;

            // color: $colorFormLabel;
        }
        .input {
            margin: 10px 0;
        }
        .checkbox,
        .radio {
            display: flex;
            align-items: flex-start;

            label {
                padding-left: 26px;
            }
            [type="checkbox"] {
                position: absolute;
                
                margin-top: 5px;
            }
        }
    }
    .form-actions {
        margin-top: 28px;
        .btn {
            min-width: 200px;
        }
    }
    .control-label {
        color: $colorFormLabel;
    }
}

.variant--rounded {
    form {
        input {
            &[type="text"],
            &[type="date"],
            &[type="email"],
            &[type="month"],
            &[type="number"],
            &[type="password"],
            &[type="tel"],
            &[type="time"],
            &[type="url"],
            &[type="file"] {
                border-radius: 10px;
            }
        }
        select,
        textarea {
            border-radius: 10px;
        }
    }
}
  
.variant--oblong {
    form {
        input {
            &[type="text"],
            &[type="date"],
            &[type="email"],
            &[type="month"],
            &[type="number"],
            &[type="password"],
            &[type="tel"],
            &[type="time"],
            &[type="url"],
            &[type="file"] {
                border-radius: 20px;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        select,
        textarea {
            border-radius: 20px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}