.footer-nav {
    background-color: $colorFooterBg;
    padding: 25px 0;

    @include breakpoint(lg) {
        padding: 50px 0;
    }
    .footer-nav__container {
        width: 90%;
        max-width: map-get($breakpoints, $key: lg);
        margin: 0 auto;
        text-align: center;
        & * {
            color: $colorFooterText;
        }
    }
    .footer-nav__content {
        & > * {
            margin-bottom: 1rem;
        }
    }
    .footer-nav__icon {
        font-size: calc(1.25 * #{$fontPrimarySize});
        margin: 24px 0;

        .social-links {
            justify-content: center;
        }
    }
    .footer-nav__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;

        padding: 0;

        list-style-type: none;
        font-style: italic;

        @include breakpoint(md) {
            flex-direction: row;
        }

        li {
            & + li {
                margin: 4px 0 0 0;
                @include breakpoint(md) {
                    margin: 0 0 0 2px;
                }
                &::before {
                    @include breakpoint(md) {
                        content: '-';
                        margin: 0 5px;
                    }
                }
            }
        }
        a {
            display: inline-block;
            padding: 2px;

            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}