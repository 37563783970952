.header-nav {
	position: relative;
	z-index: 5;

	background-color: $white;

	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);

	&--fixed {
		position: sticky;
		z-index: 100;
		top: -1px;
	}

	&--sticky {
		position: sticky;
		z-index: 100;
		// top: 0px;
	}

	ul {
		list-style: none;
		margin: 0;
	}

	li {
		margin: 0;
	}
	// HEADER NAV BURGER
	&.header-nav--burger {
		.header-nav__burger {
			@include breakpoint(xl) {
				display: flex;
			}
		}
		.header-nav__menu {
			display: none;
			position: fixed;
			top: 94px;
			right: 0;
			z-index: 99;

			width: 100%;
			height: calc(100vh - 94px);
			box-sizing: border-box;

			padding: 30px 20px;

			background-color: $white;

			@include breakpoint(md) {
				width: 450px;
			}
			&.active {
				display: block;
			}
		}
		.header-nav__link {
			&:not([class*="header-nav__link--"]) {
				padding: 5px;
			}
		}
		.header-nav__link-container {
			& + .header-nav__link-container {
				margin-top: 10px;
			}
		}
	}

  	&__container {
		width: 90%;
		max-width: $lg;

		display: flex;
		align-items: center;
		justify-content: space-between;

		padding: 15px 0;
		margin: 0 auto;

		&--wide {
			max-width: 1740px;
		}
  	}

  	&__brand {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		max-height: 70px;
		max-width: 240px;
		height: 70px;
		//   margin: 0 5px;
		// padding: 4px 10px;

		// color: $textColor;
		// background-color: $colorPrimary;

		// font-weight: bold;

		// border: 1px solid $colorPrimary;

		@include breakpoint(lg) {
			height: 76px;
		}

		img {
			width: 100%;
			height: auto;
			// max-height: 70px;
			max-width: 100%;
		}
  	}

  	&__menu {
		display: none;

		* {
			color: $black;
		}

		@media screen and (min-width: $xl) {
			display: block;

			> ul {
				display: flex;
				align-items: center;
				gap: 14px;
			}
		}
		@media screen and (max-width: 1279px) {
			position: fixed;
			top: 94px;
			right: 0;
			z-index: 99;

			width: 450px;
			height: calc(100vh - 94px);
			box-sizing: border-box;

			padding: 30px 20px;

			background-color: $white;
		}
		@media screen and (max-width: 639px) {
			width: 100%;
		}
		&.active {
			@media screen and (max-width: 1279px) {
				display: block;
				.header-nav__link-container {
					& + .header-nav__link-container {
						margin-top: 10px;
					}
				}
				.header-nav__link {
					display: inline-block;

					font-size: $fontPrimarySize;
				}
				& + .header-nav__overlay {
					display: block;
				}
			}
		}
  	}

	&__overlay {
		display: none;

		position: fixed;
		top: 94px;
		left: 0;
		z-index: 90;

		background-color: rgba(0,0,0,0.65);
		width: 100%;
		height: calc(100vh - 94px);
	}

  	&__link {
		text-decoration: none;

		padding: 5px;

		white-space: nowrap;

		@include breakpoint(lg) {
			padding: 0;
		}

		&--no-color {
			color: $black !important;
			background-color: unset!important;
			padding: 0;
		}

		&--primary {
			color: $white !important;
			background-color: $colorPrimary !important;
			border: 1px solid $colorPrimary !important;

			padding: 8px 13px;

			&:hover {
				background-color: $white !important;
				color: $colorPrimary !important;
			}
		}
		
		&--primary-dark {
			color: $white !important;
			background-color: $colorPrimaryDark !important;
			border: 1px solid $colorPrimaryDark !important;

			padding: 8px 13px;
			&:hover {
				background-color: $white !important;
				color: $colorPrimaryDark !important;
			}
		}

		&--primary-light {
			color: $black !important;
			background-color: $colorPrimaryLight !important;
			border: 1px solid $colorPrimaryLight !important;

			padding: 8px 13px;
			&:hover {
				background-color: $black !important;
				color: $colorPrimaryLight !important;
			}
		}
		
		&--secondary {
			color: $white !important;
			background-color: $colorSecondary !important;
			border: 1px solid $colorSecondary !important;

			padding: 8px 13px;
			&:hover {
				background-color: $white !important;
				color: $colorSecondary !important;
			}
		}
  	}

  	&__link-container {
		display: flex;
		flex-direction: column;
		justify-content: center;

		position: relative;
  	}

  	&__link-container:hover & {
		&__sublinks {

			@include breakpoint(lg) {
				opacity: 1;

				pointer-events: all;
			}
		}
  	}

	&__sublinks {

		@include breakpoint(lg) {
			width: 250px;

			display: flex;
			flex-direction: column;
	
			position: absolute;
			top: 100%;
			left: 50%;
	
			padding: 20px 0 0 0;
	
			opacity: 0;
	
			transform: translateX(-50%);
	
			pointer-events: none;
	
			transition: opacity .3s ease-in-out;
		}
	}

	&__sublinks-container {
		padding: 0 0 0 20px;
		@include breakpoint(lg) {
			display: flex;
			flex-direction: column;
	
			padding: 0 20px;
	
			background-color: $white;
	
			box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
		}
	}

	&__sublink {
		padding: 5px;

		width: 100%;

		text-decoration: none;
		text-align: left;
		@include breakpoint(lg) {
			text-align: center;

			padding: 20px 0;

			border-bottom: 1px solid gray;
		}

		&:last-child {
			border-bottom: none;
		}
	}

	&__burger {
		width: 35px;
		height: 25px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;

		margin: 0 5px;
		padding: 0;

		border: none;
		background-color: rgba(0,0,0,0);

		cursor: pointer;

		& > span {
			$barHeight: 5px;

			width: 100%;
			height: $barHeight;

			background-color: $black;

			border-radius: 999px;

			transition: all 50ms ease-in;

			&::after,
			&::before {
				content: "";
				display: block;
				position: absolute;
				
				width: 100%;
				height: $barHeight;

				background-color: $black;

				border-radius: 999px;
			}
			&::before {
				top: 0;

				transition: top 0.15s 0.15s, transform 0.15s;
			}
			&::after {
				top: 20px;

				transition: top 0.15s 0.15s, transform 0.15s;
			}
		}

		&.active {
			& > span {
				background-color: rgba(0,0,0,0);
				&::before {
					top: 10px;
					transform: rotate(-45deg);
					transition: top 0.15s, transform 0.15s 0.15s;
				}
				&::after {
					top: 10px;
					width: 100%;
					transform: rotate(45deg);
					transition: top 0.15s, transform 0.15s 0.15s;
				}
			}
		}

		@media screen and (min-width: $xl) {
			display: none;
		}
	}

	&--xl & {
		&__container {
			max-width: $xl;
		}
	}

	&--2xl & {
		&__container {
			max-width: $xl2;
		}
	}

	& {
		&.frame-bg--primary {
			background-color: $colorHeaderBg;
		}

		&.frame-bg--primary & {
			&__link {
				color: $colorHeaderText !important;
			}
		}
	}

	& {
		&.frame-font--white & {
			&__link {
				color: $white !important;
			}
		}
	}

	& {
		&.frame-style--fixed {
			width: 100%;

			position: fixed;
			z-index: 1000;
			top: 0;
			left: 0;
		}
	}

	& {
		&.frame-style--transparent {
			width: 100%;
			
			background-color: $black;

			box-shadow: none;
		}

		&.frame-style--transparent & {
			&__link {
				color: $black !important;
			}
		}
	}

	&--primary-dark & {
		&__link {
			padding: 10px 20px;

			color: $textColor;
			background-color: $colorPrimaryDark;
		}
	}

	&--secondary & {
		&__link {
			padding: 10px 20px;

			color: $textColor;
			background-color: $colorSecondary;
		}
	}

	& {
		&--no-shadow {
			box-shadow: none;
		}
	}
}

.variant--oblong {
	.header-nav {
		&__link {
			border-radius: 999px;
		}

		&__sublinks-container {
			border-radius: 10px;
		}
	}
}

.variant--rounded {
	.header-nav {
		&__link {
			border-radius: 4px;
		}

		&__sublinks-container {
			border-radius: 4px;
		}
	}
}