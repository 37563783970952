.page-header {
    position: relative;

    &__container {
        position: relative;
        
        display: flex;
        align-items: center;

        background-color: $colorPrimaryDark;

        @include breakpoint(lg) {
            min-height: 315px;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        width: 100%;
        height: 100%;

        background-size: cover;
        background-position: center;

        opacity: 0.3;
    }
    .container {
        position: relative;
        z-index: 1;

        padding-top: 30px;
        padding-bottom: 30px;

        color: $white;
    }
}