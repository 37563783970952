.container {
    width: 90%;
    margin: 0 auto;

    @include breakpoint(md) {
        width: 87.5%;
        max-width: 1140px;
    }
    &.container--wide {
        width: 100%;
        @include breakpoint(md) {
            width: 100%;
            max-width: 1930px;

            padding: 0 20px;
        }
        @include breakpoint(lg) {
            padding: 0;
        }
    }
}