.frame-type-card_group {
    &.frame--gray {
        .card-body {
            background-color: $white;
        }
    }
    .container--wide {
        // overflow-x: hidden;
        @include breakpoint(lg) {
            padding: 0 44px;
        }
        @include breakpoint(xl2) {
            padding: 0 80px;
        }
        .card-group-element {
            padding-left: 20px;
            padding-right: 20px;
            @include breakpoint(md) {
                margin-left: -40px;
                padding-left: 0;
                padding-right: 0;
            }
            @include breakpoint(3xl) {
                margin-left: -65px;
            }
            &.card-group-element-columns-2 {
                .card-group-element-item {
                    @include breakpoint(md) {
                        width: calc(50% - 40px);
                    }
                    @include breakpoint(3xl) {
                        width: calc(50% - 65px);
                    }
                }
            }
            &.card-group-element-columns-3 {
                .card-group-element-item {
                    @include breakpoint(md) {
                        width: calc(50% - 40px);
                    }  
                    @include breakpoint(xl) {
                        width: calc(33% - 40px);
                    }
                    @include breakpoint(3xl) {
                        width: calc(33% - 65px);
                    }
                }
            }
            &.card-group-element-columns-4 {
                .card-group-element-item {
                    @include breakpoint(md) {
                        width: calc(50% - 40px);
                    }  
                    @include breakpoint(2xl) {
                        width: calc(25% - 40px);
                    }
                    @include breakpoint(3xl) {
                        width: calc((100% / 4) - 65px);
                    }
                }
            }
            &.card-group-element-columns-5 {
                .card-group-element-item {
                    @include breakpoint(md) {
                        width: calc(50% - 40px);
                    }  
                    @include breakpoint(lg) {
                        width: calc(33% - 40px);
                    } 
                    @include breakpoint(2xl) {
                        width: calc((100% / 5) - 40px);
                    }
                    @include breakpoint(3xl) {
                        width: calc((100% / 5) - 65px);
                    }
                }
            }
            &.card-group-element-columns-6 {
                .card-group-element-item {
                    @include breakpoint(md) {
                        width: calc(50% - 40px);
                    }  
                    @include breakpoint(lg) {
                        width: calc(33% - 40px);
                    } 
                    @include breakpoint(2xl) {
                        width: calc((100% / 6) - 40px);
                    }
                    @include breakpoint(3xl) {
                        width: calc((100% / 6) - 65px);
                    }
                }
            }
        }
        .card-group-element-item {
            width: 100%;

            @include breakpoint(md) {
                margin: 0 0 40px 40px;
            }
            @include breakpoint(3xl) {
                margin: 0 0 40px 65px;
            }
        }
        .card-body {
            @include breakpoint(xl) {
                padding: 38px 46px;
            }
        }
    }

    .frame-header {
        margin-bottom: 40px;

        text-align: center;
    }

    .card-group-element {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        max-width: 450px;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(md) {
            max-width: calc(100% + 40px);

            margin-left: -20px;
            margin-bottom: -20px;
            margin-right: 0;

            overflow: hidden;
        }
        @include breakpoint(3xl) {
            max-width: calc(100% + 65px);
        }
        &.card-group-element-columns-2 {
            .card-group-element-item {
                @include breakpoint(md) {
                    width: calc(50% - 20px);
                }  
            }
        }
        &.card-group-element-columns-3 {
            .card-group-element-item {
                @include breakpoint(md) {
                    width: calc(50% - 20px);
                }  
                @include breakpoint(xl) {
                    width: calc(33% - 20px);
                } 
            }
        }
        &.card-group-element-columns-4 {
            .card-group-element-item {
                @include breakpoint(md) {
                    width: calc(50% - 20px);
                }  
                @include breakpoint(2xl) {
                    width: calc(25% - 20px);
                }
            }
        }

        &.card-group-element-columns-auto {
            flex-wrap: wrap;
            @include breakpoint(md) {
                flex-wrap: nowrap;
            }
            .card-group-element-item {
                flex: 1 0 100%;
                @include breakpoint(md) {
                    flex: 0 1 auto;
                }
            }
        }
        &.card-group-element-img-left {
            .card {
                flex-direction: row;
                align-items: center;
                gap: 20px;

                @include breakpoint(md) {
                    gap: 30px;
                }
                &-img-left {
                    flex-shrink: 0;

                    width: 90px;
                }
            }
        }
        &.card-group-element-img-right {
            max-width: calc(100% + 40px);
            @include breakpoint(3xl) {
                max-width: calc(100% + 65px);
            }
            @media screen and (min-width: 640px) and (max-width: 767px) {
                .card {
                    flex-direction: row-reverse;
                    align-items: stretch;
                }
                .card-img-top {
                    width: 50%;
                    height: auto;
                }
                .card-body {
                    padding: 45px 38px;
                }
            }
            @include breakpoint(xl) {
                .card {
                    flex-direction: row-reverse;
                    align-items: stretch;
                }
                .card-img-top {
                    width: 50%;
                    height: auto;
                }
                .card-body {
                    padding: 45px 38px;
                }
            }
        }
    }
    .card-group-element-item {
        overflow: hidden;
        margin-bottom: 20px;
        @include breakpoint(md) {
            margin: 0 0 20px 20px;
        }
    }
    .card {
        display: flex;
        flex-direction: column;

        height: 100%;
    }
    .card-title {
        font-weight: 700;

        color: $colorCardTitle;
    }
    .card-body {
        flex-grow: 1;

        font-size: $fontPrimarySize;

        background-color: $colorGrayLight;
        padding: 20px;

        @include breakpoint(xl) {
            padding: 35px;
        }

        p {
            line-height: 1.5;

            @include breakpoint(lg) {
                line-height: 1.7;
            }
        }
    }
    .card-img-top {
        flex-shrink: 0;

        height: 245px;

        overflow: hidden;

        picture {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 100%;
            width: 100%;
        }
        img {
            object-fit: cover;

            min-height: 100%;
            min-width: 100%;
        }
    }

    & {
        &.bg--gray {
            .card-body {
                background-color: $white;
            }
        }
    }

    & {
        &--primary & {
            .frame-header {
                color: $colorPrimary;
            }
        }

        &--primary {
            .card-title {
                color: $colorPrimary;
            }
        }
    }

    & {
        &--primary-dark & {
            .frame-header {
                color: $colorPrimaryDark;
            }
        }

        &--primary-dark {
            .card-title {
                color: $colorPrimaryDark;
            }
        }
    }
    
    & {
        &--primary-light & {
            .frame-header {
                color: $colorPrimaryLight;
            }
        }

        &--primary-light {
            .card-title {
                color: $colorPrimaryLight;
            }
        }
    }

    & {
        &--secondary & {
            .frame-header {
                color: $colorSecondary;
            }
        }

        &--secondary {
            .card-title {
                color: $colorSecondary;
            }
        }
    }

    & {
        &.frame-bg {
            &--primary {
                background-color: $colorPrimary;

                .frame-header {
                    color: $textColor;
                }

                .card-body {
                    background-color: $colorPrimary;

                    border: 1px solid $textColor;

                    .card-title {
                        color: $textColor;
                    }

                    * {
                        color: $textColor;
                    }
                }
            }
        }
    }

    &.frame-font--primary {
        .frame-header {
            color: $colorPrimary;
        }

        * {
            color: $colorPrimary;
        }
    }
}

.variant--rounded {
    .card-group-element-item {
        border-radius: 10px;
    }
}
  
.variant--oblong {
    .card-group-element-item {
        border-radius: 20px;
    }
} 
