/**
 * Mixin dédiée à la génération des média queries mobile first
 *
 * @param $breakpoint: Nom d'un "point de rupture" déclaré dans $breakpoints définie dans _variables.scss
 * @param $logic: Condition supplémentaire à ajouter à la media query
 *
 * Exemple: @include breakpoint(m) {} / @include breakpoint(s, "screen") {}
 */
 @mixin breakpoint($breakpoint, $logic: false) {
    @if ($logic) {
		@media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
			@content;
		}
    } @else {
		@media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
			@content;
		}
    }
}

// Colonnage
@mixin create-cols($span) {
    .card-group-elements-columns-#{$span} {
		.card-group-element-item {
			width: (100%/($span));
		}
    }
}
  