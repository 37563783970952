.alert {
    position: relative;

    padding: 8px 16px 8px 68px;
    margin: 12px 0;
    
    border: 1px solid $colorPrimary;
    color: $colorPrimary;

    &::before {
        content: '';
        position: absolute;
        top: calc(50% - 17.5px);
        left: 16px; 

        width: 35px;
        height: 35px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 34px auto;
    }

    $colors: 
        "success" $success, 
        "danger" $danger,
        "warning" $warning;

    @each $element, $color in $colors {
        &.alert-#{$element} {
            color: $color;
            border-color: $color;

            &::before {
                background-image: url('../../assets/img/icon-#{$element}.svg');
            }
        }
    }
}