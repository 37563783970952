:root {
    --color-primary: #1960ff;
    --color-secondary: #ffe600;
    --color-primary-light: #289aff;
    --color-primary-dark: #0a2666;
    --color-gray: #4e576e;
    --color-gray-light: #f3f3f3;

    --white: #FFFFFF;
    --black: #000000;

    --success: #81FF5F;
    --warning: #F9AF14;
    --danger: #FA815A;

    --text-color: var(--white);

    --primary-font: 'Poppins', sans-serif;
    --secondary-font: 'Poppins', sans-serif;

    --primary-font-size: 1.6rem;
    --secondary-font-size: 1.6rem;
}

$colorPrimary: var(--color-primary);
$colorPrimaryLight: var(--color-primary-light);
$colorPrimaryDark: var(--color-primary-dark);
$colorSecondary: var(--color-secondary);
$colorGray: var(--color-gray);
$colorGrayLight: var(--color-gray-light);

$textColor: var(--text-color);

$white: var(--white);
$black: var(--black);

$success: var(--success);
$warning: var(--warning);
$danger: var(--danger);

$gray-100: #f8f8f8;
$gray-150: #f5f5f5;
$gray-200: #ececec;
$gray-300: #dcdcdc;
$gray-500: #ababab;
$gray-600: #9e9e9e;
$gray-700: #6b6b6b;
$gray-800: #383838;

$fontPrimary: var(--primary-font);
$fontSecondary: var(--secondary-font);
$fontPrimarySize: var(--primary-font-size);
$fontSecondarySize: var(--secondary-font-size);

$xs: 520px;
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xl2: 1536px;
$xl3: 1780px;

$breakpoints: (
  xs: 520px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px,
  3xl: 1780px,
);

// Header
$colorHeaderBg: $colorPrimary;
$colorHeaderText: $textColor;

// Footer
$colorFooterBg : $colorPrimaryDark;
$colorFooterText : $white;

// Accordion
$colorAccordionTitle: $black;
$colorAccordionBg: $white;
$colorAccordionBorder: $colorGrayLight;
$colorAccordionActiveBg: $colorGrayLight;
$colorAccordionActiveTitle: $colorPrimaryDark;

// Carousel
$colorSlideText: $white;
$colorSlideBg: $colorPrimary;
$colorSlideDots: $white;
$colorSlideBtnBg: $white;
$colorSlideBtnIcon: $colorPrimary;

// Card group
$colorCardTitle: $colorPrimaryDark;

// Icon group
$colorIconTitle: $gray-700;

// News
$colorNewsTitle: $colorPrimaryDark;
$colorNewsTagBg: $colorPrimaryDark;
$colorNewsTagText: $white;
$colorNewsDate: $colorGray;

// Uploads
$colorUploadsTitle: $colorPrimaryDark;
$colorUploadsIcon: $black;
$colorUploadsBorder: $colorGrayLight;
$colorUploadsBg: $white;

// Table
$colorTableBorder: $colorGrayLight;
$colorTableHead: $colorPrimaryDark;

// Forms
$colorInputBorder: $colorGrayLight;
$colorInputBg: $colorGrayLight;
$colorFormLabel: $black;