.theme--petit-tonnerre {
    .frame {
        &:not(.slider-section) {
            h2 {
                text-transform: uppercase;
                font-weight: 900;
            }
        }
    }

    .frame-type-banner {
        .background-image {
            opacity: 0.6;
        }
        .ce-bodytext {
            border: 1px solid $white;
            background-color: rgba(255,255,255, 0.3);

            padding: 30px;
        }
    }

    p {
        font-size: calc(1.25 * #{$fontPrimarySize});

        line-height: 175%;
    }

    ul {
        font-size: calc(1.25 * #{$fontPrimarySize});

        line-height: 175%;
    }
}

.theme--petit-tonnerre-v2 {
    // PAGE D'ACCUEIL :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    &.home {
        .section {
            padding: 36px 0;
            @include breakpoint(md) {
                padding: 54px 0;
            }
            @include breakpoint(xl) {
                padding: 80px 0;
            }
            @include breakpoint(xl2) {
                padding: 100px 0;
            }
        }
        .frame {
            &:not(.frame-type-imageseparator):not(.frame-type-slider) {
                padding: 0;
                & + .frame {
                    padding-top: 30px;
                    @include breakpoint(md) {
                        padding-top: 40px;
                    }
                    @include breakpoint(xl) {
                        padding-top: 55px;
                    }
                }
            } 
        }
    }
    // ELEMENTS GENERAUX :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    blockquote {
        position: relative;

        padding: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 95px;
        box-sizing: border-box;

        max-width: 955px;

        color: $colorPrimary;
        border-color: $colorPrimary;

        font-size: 2rem;
        text-align: center;

        &::before {
            content: url('../../assets/svg/quote-left.svg');
        }
        &::after {
            content: url('../../assets/svg/quote-right.svg');
        }

        p {
            margin: 0;
            line-height: 1.2;
        }

        .blockquote-icon {
            width: 85px;

            position: absolute;
            z-index: 3;
            right: 5%;
            top: 0px;

            transform: translate(0, -70%);

            @include breakpoint(md) {
                width: 115px;
            }
        }
    }

    small {
        font-size: 1.6rem;
    }

    .header-nav {
        z-index: 10;
    }

    .header-nav--sticky {

    }

    .header-nav__link--secondary {
        color: $colorPrimary !important;

        font-weight: 700;
    }

    .header-nav__link {
        min-width: 100px;

        display: flex;
        align-items: center;
        justify-content: center;

        padding: 11px 30px;

        line-height: 1;

        @include breakpoint(xl) {
            font-size: 1.6rem;
        }
        @include breakpoint(2xl) {
            font-size: 1.8rem;
        }
    }

    .section {
        padding: 30px 0;
        @include breakpoint(md) {
            padding: 46px 0;
        }
        @include breakpoint(xl) {
            padding: 58px 0;
        }
        @include breakpoint(xl2) {
            padding: 70px 0;
        }
    }

    .highlight {
        background-color: $textColor;
        color: $colorPrimary !important;
    }
    
    .highlight-padding {
        display: inline-flex;
        align-items: center;
        position: relative;

        margin: 0 0 20px 0;
        padding: 10px 28px;

        background-color: $colorSecondary;
        color: $colorPrimary;

        text-decoration: none;

        @include breakpoint(sm) {
            padding: 10px 35px;
        }

        &:hover {
            &::after {
                transform: translateX(10px) rotate(3deg);

                transition: transform 300ms ease-in;
            }
        }

        &::after {
            content: '';
            width: 40px;
            height: 20px;

            background-image: url('../../assets/svg/fleche.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            margin-left: 20px;

            transform: translateX(0px) rotate(0deg);

            transition: transform 300ms ease-in;
        }
        
        * {
            color: $colorPrimary !important;
        }

        .price {
            font-size: 2.8rem;
            font-weight: bold;

            @include breakpoint(sm) {
                font-size: 3.1rem;
            }
            @include breakpoint(lg) {
                font-size: 3.5rem;
            }
        }

        .icon {
            width: 50px;
            height: auto;

            margin-left: 40px;

            opacity: 0.3;
        }
    }

    .btn--primary {
        &:hover {
            background-color: $colorSecondary;
            color: $colorPrimary;
        }
    }
    .btn--secondary {
        &:hover {
            background-color: $colorPrimary;
            color: $colorSecondary;
        }
    }
    
    .heading {
        line-height: 150%;
    }
    .bg--primary {
        color: $white;
        a,
        hr {
            color: $white;
        }
        form {
            .control-label {
                color: $white;
                cursor: pointer;
            }
        }
        .social-links {
            a {
                &:hover {
                    color: $colorSecondary;
                }
            }
        }
    }
    .btn  {
        font-weight: 700;
    } 
    .btn--primary {
        color: $colorSecondary;
        &:hover {
            background-color: $colorPrimaryDark;
        }
    }
    .btn--secondary {
        color: $colorPrimary;
    }

    // HEADER :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    .header-nav {
        .header-nav__brand {
            height: 64px;
            max-height: 64px;
            width: 220px;
            @include breakpoint(lg) {
                width: 260px;
                height: 76px;
                max-height: 76px;
            }
        }
    }
    .header-nav__link--secondary {
        color: $colorPrimary !important;

        font-weight: 700;
    }

    .header-nav__link {
        min-width: 100px;

        display: flex;
        align-items: center;
        justify-content: center;

        $padding: 15px;
        padding-left: $padding;
        padding-right: $padding;
    }

    .header-nav__burger {
        &:not(.active) {
            & > span {
                background-color: $colorPrimary;
            }
        }
        & > span {
            &::before,
            &::after {
                background-color: $colorPrimary;
            }
        }
    } 

    // FOOTER :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    footer {
        padding: 0;

        a {
            text-decoration: none;
        }
        hr {
            display: none;
            @media screen and (min-width: 992px) {
                display: block;
                margin: 30px 0;
            }
        }
        .container {
            max-width: 1400px;
        }
        .row {
            flex-direction: column-reverse;
            @media screen and (min-width: 992px) {
                flex-direction: row;
            }
        }
        .col-lg-4 {
            text-align: center;

            margin-top: 30px;
            @media screen and (min-width: 992px) {
                text-align: left;

                margin: 0;
            }
            p {
                line-height: 1.3;
            }
        }
        .social-links {
            // display: none;
            justify-content: center;

            margin: 30px 0;
            @media screen and (min-width: 992px) {
                justify-content: flex-start;
            }
            a {
                font-size: 2.4rem;
            }
        }
        img {
            display: block;

            margin: 36px auto 30px auto;
            max-width: 295px;

            @include breakpoint(lg) {
                margin: 30px auto 30px 0;
            }
        }
        .tp-reviews {
            // outline: 3px solid red;
            border-top: 1px solid $white;
            padding-top: 20px;
            &__heading {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                gap: 10px;

                font-weight: 700;
                img {
                    width: 122px;

                    margin: 0;
                }
            }
            &__infos {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 16px;

                margin-bottom: 22px;
            }
            &__stars {
                display: flex;
                align-items: center;
                gap: 8px;

                list-style-type: none;

                padding: 0;
                margin: 2px 0;
                li {
                    width: 20px;
                    height: 20px;
                    // background-color: pink;

                    margin: 0;
                }
                .star {
                    background-image: url("../../assets/svg/star--full.svg");
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    &--half {
                        background-image: url("../../assets/svg/star--half.svg");
                    }
                    &--empty {
                        opacity: 0.5;
                    }
                }
            }
            &__review {
                margin: 24px 0;
            }
        }
        .review {
            text-align: left;
            &__title {
                // font-weight: 700;
            }
            &__content {
                font-size: 1.5rem;
            }
            &__infos {
                font-size: 1.5rem;
                font-style: italic;
                text-align: right;
            }
        }
        .form-container {
            @include breakpoint(lg) {
                padding-left: 36px;
            }
            @include breakpoint(xl) {
                padding-left: 60px;
            }
            @include breakpoint(2xl) {
                padding-left: 90px;
            }
        }
        form {
            padding: 20px 22px;
            border: 1px solid $white;
            @include breakpoint(sm) {
                padding: 20px 40px;
            }
            @include breakpoint(lg) {
                font-size: 1.8rem;
                padding: 26px 65px;
            }
            .form-group {

                margin-bottom: 2px;
                @include breakpoint(sm) {
                    display: flex;
                    align-items: flex-start;
                }
                .input {
                    flex-grow: 1;
                }
                .control-label {
                    @include breakpoint(sm) {
                        width: 150px;
                        margin-left: 10px;
                        margin-top: 18px;
                    }
                }
                .checkbox {
                    width: 100%;
                    @include breakpoint(sm) {
                        margin-left: 160px;
                        width: calc(100% - 160px);
                    }
                    .control-label {
                        width: 100%;
                        margin-top: 0;
                    }
                }
            }
            .form-actions {
                text-align: center;
                @include breakpoint(sm) {
                    margin-left: 160px;
                    text-align: left;
                }
            }
            div.g-recaptcha {
                // text-align: center;
                display: flex;
                justify-content: center;

                margin-top: 20px;
                @include breakpoint(sm) {
                    justify-content: flex-start;
                    
                    margin-left: 160px;
                    // text-align: left;
                }
            }
        }
    }

    .footer-nav {
        &__container {
            max-width: 1400px;
            * {
                color: $colorPrimary;
            }
        }
        &__menu {
            font-style: normal;

            @include breakpoint(lg) {
                justify-content: space-between;
            }
            li {
                & + li {
                    &::before {
                        @include breakpoint(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // CONTENUS (frames) :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // Container 2cols :::::::::::
    .frame-type-container-2cols {
        .container {
            max-width: 1400px;
        }
        .social-links {
            margin: 30px 0;
            a {
                font-size: 2.4rem;
            }
        }
        img {
            display: block;

            margin: 30px auto;
            max-width: 235px;
        }
        form {
            @include breakpoint(lg) {
                font-size: 1.8rem;
            }
            .form-group {
                margin-bottom: 2px;
            }
            .checkbox {
                @include breakpoint(sm) {
                    margin-left: 160px;
                }
            }
            .form-actions {
                @include breakpoint(sm) {
                    margin-left: 160px;
                }
            }
        }
    }
    // Icon group :::::::::::
    .frame-type-icon_group {
        // .frame-header {
        //     h2 {
        //         font-size: 3.2rem;
        //     }
        // }
        &.frame--row {
            .container {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 0px;
    
                @include breakpoint(lg) {
                    flex-direction: row;
                    gap: 50px;
                }
                @include breakpoint(xl) {
                    gap: 90px;
                }
                @include breakpoint(2xl) {
                    gap: 120px;
                }
            }
    
            .frame-header {
                max-width: 300px;
    
                margin: 0;
    
                text-align: center;
    
                *  {
                    color: $colorPrimary;
                }
    
                @include breakpoint(lg) {
                    text-align: left;
                }
            }
    
            .icongroup {
                @include breakpoint(lg) {
                    flex-grow: 1;
                }
            }
    
            .icongroup-item {
                display: flex;
                align-items: center;
                justify-content: center;
    
                padding: 10px 30px;
                box-sizing: border-box;
                min-height: 140px;
    
                border: 1px solid $colorPrimary;
                @include breakpoint(lg) {
                    padding: 10px 45px;
                }
            }
        }
        .container {
            max-width: 1400px;
        }

        .icongroup-item-text-header {
            font-size: 2rem;
        }
        .icongroup-item-icon {
            max-width: 170px;
        }
    }
    // Card group :::::::::::
    .frame-type-card_group {
        .card-group-element {
            overflow: unset;
        }

        .card-group-element-item.card-group-element-item--with-attribut {
            position: relative;

            font-size: 2rem;

            overflow: unset;
            
            &::before {
                content: attr(data-number);

                width: 50px;
                height: 50px;

                display: flex;
                align-items: center;
                justify-content: center;

                position: absolute;
                z-index: 3;

                color: $colorPrimary;
                background-color: $textColor;

                font-size: 3.5rem;

                border: 4px solid $colorPrimary;
                border-radius: 50%;

                transform: translate(-50%, -50%);

            }
        }

        .petit-tonnerre-talk {
            display: flex;
            align-items: center;
            justify-content: center;

            margin: 0 auto;
            margin-top: 25px;

            &__icon {
                width: 75px;
                height: auto;

                margin-right: 30px;
            }

            &__text {
                max-width: 260px;

                color: $textColor;

                font-size: 2.3rem;

                @include breakpoint(xl) {
                    font-size: 3.2rem;
                }
                
                * {
                    font-family: 'Indie Flower', cursive;
                }
            }
        }
    }

    .frame-footer {
        * {
            color: $textColor;
        }
    }

    .frame-type-card_group.frame-text--center {
        .card {
            text-align: center;
        }

        .card-body {
            padding: 50px 32px;
        }

        .card-title {
            margin: 0;
        }
    }
    // Formulaire :::::::::::
    .frame-type-formframework {
        form {
            padding: 20px 40px;

            background-color: $colorPrimary;

            border: 1px solid $textColor;

            *:not(select, input, option, textarea) {
                color: $textColor;
            }

            select, input, option, textarea {
                color: $black;

                &::placeholder {
                    color: rgb(117, 117, 117);
                }
            }

            button {
                color: $textColor;
                background-color: $colorPrimary;

                border: 1px solid $textColor;
            }
        }
        button {
            color: $colorPrimary !important;
            background-color: $colorSecondary !important;

            font-weight: 700;

            border: none !important;
        }
    }

    /* .frame-type-text .ce-bodytext {
        *:not(blockquote){
            color: $textColor;
        }

        strong {
            color: $colorPrimary;
        }
    } */
    // Carrousel :::::::::::
    .frame-type-slider {
        .carousel--hero {
            .highlight {
                &-wrapper {
                    position: relative;
                    overflow: hidden;
                    display: block;

                    height: 45px;
                    width: 100%;

                    .highlight {
                        position: absolute;
                        left: 0;
                        top: 0;

                        animation-name: fadeslideUp;
                        animation-iteration-count: infinite;
                        animation-timing-function: ease-in-out;
                        animation-duration: 9s;
                        &--1 {
                            animation-delay: 0s;
                        }
                        &--2 {
                            animation-delay: -3s;
                        }
                        &--3 {
                            animation-delay: -6s;
                        }
                    }
                }
            }
        }
        .carousel--reals {
            .slide {
                @include breakpoint(md) {
                    padding-bottom: 37px;
                }
                &-image {
                    height: 100%;
                    img {
                        opacity: 1;
                    }
                } 
                &-container {
                    background-color: rgba(25, 96, 255,0.88);

                    margin-top: 90px;
                    margin-bottom: 62px;
                    padding: 20px 22px;
                    @include breakpoint(sm) {
                        padding: 24px 30px;
                    }
                    @include breakpoint(md) {
                        margin-top: 125px;
                        margin-bottom: 0;

                        font-size: 1.5rem;

                        min-height: 196px;
                        max-width: 490px;
                    }
                    @include breakpoint(xl) {
                        margin-top: 160px;
                        padding: 32px 46px;

                        font-size: 1.8rem;

                        min-height: 235px;
                        max-width: 610px;
                    }
                    .btn {
                        padding: 8px 22px;

                        @include breakpoint(md) {
                            padding: 10px 30px;
                        }
                    }
                }
            } 
        }
        .carousel--arrows-bottom {
            .slide-container {
                @include breakpoint(lg) {
                    max-width: 565px;
                }
                .heading {
                    max-width: 412px;
                    line-height: 1.25;
                }
            }
        }
        .slide-container {
            .heading {
                @include breakpoint(xl) {
                    margin-bottom: 42px;
                }
            }
        }
    }

    .header-nav {
        .header-nav__brand {
            height: 64px;
            max-height: 64px;
            width: 220px;
            @include breakpoint(lg) {
                width: 260px;
                max-width: 260px;
                height: 76px;
                max-height: 76px;
            }
        }
        &__menu {
            & > ul {
                @include breakpoint(xl) {
                    gap: 20px
                }
                @include breakpoint(2xl) {
                    gap: 40px
                }
            }
        }
        &__container--wide {
            max-width: 1800px;
            @include breakpoint(2xl) {
                width: 90%;
            }
            @include breakpoint(xl) {
                width: 93%;
            }
        }
    }

    .frame-type-card_group.petit-tonnerre-projet {
        .container--wide {
            max-width: 1760px;
            width: 95%;
            @include breakpoint(lg) {
                padding: 0 44px;
            }
        }
        .card-group-element-item.card-group-element-item--with-attribut {
            font-family: 'Indie Flower', cursive;
            padding-left: 16px;
        }

        .card-body {
            padding: 25px 15px;
            @include breakpoint(md) {
                padding: 25px;
            }
            p {
                color: $white;
                margin-bottom: 0;
            }
        }
    }
    // Accordéons :::::::::::
    .frame-type-accordion {
        .frame-header {
            margin-bottom: 25px;
            @include breakpoint(xl) {
                margin-bottom: 65px;
            }
        }
        .accordion-body {
            padding-bottom: 40px;
        }
        .accordion-content {
            color: #666;
            * {
                color: #666;
            }
        }
    }
    // Card group :::::::::::
    .frame-type-card_group {
        .frame-header {
            margin-bottom: 30px;
            @include breakpoint(xl) {
                margin-bottom: 60px;
            }
        }
    }

    .frame-type-card_group.tarifs {
        .container {
            max-width: 1020px;
        }
        .card-body {
            max-width: 368px;
            
            margin: 1px auto;

            @media screen and (max-width: 768px) {
                max-width: unset;
            }
            small {
                font-weight: 400;
            }
        }
    }

    .frame-type-icon_group.partenaires {
        .icongroup {
            flex: 1;
        }

        .icongroup-item {
            padding: 0;

            border: none;
        }

        .icongroup-item-icon {
            max-width: 200px;
        }
    }

    .card-feature {
        &.frame-type-card_group {
            .container--wide {
                max-width: 1525px;
                .card-group-element {
                    @include breakpoint(xl) {
                        // margin-left: -80px;
                        margin-left: -60px;
                    }
                    @include breakpoint(2xl) {
                        // margin-left: -120px;
                        // margin-left: -60px;
                        width: 100%;
                    }
                    &.card-group-element-columns-2 {
                        .card-group-element-item {
                            @include breakpoint(xl) {
                                margin-left: 80px;
                                margin-bottom: 80px;
                                width: calc(50% - 80px);
                            }
                            @include breakpoint(3xl) {
                                margin-left: 120px;
                                width: calc(50% - 120px);
                            }
                        }
                    }
                    &.card-group-element-columns-3 {
                        .card-group-element-item {
                            @include breakpoint(xl) {
                                margin-left: 76px;
                                margin-bottom: 52px;
                                width: calc(33% - 76px);
                            }
                            @include breakpoint(2xl) {
                                margin-left: 90px;
                                width: calc(33% - 90px);
                            }
                        }
                    }
                }
            }
            .card {
                padding: 20px;
        
                background-color: $gray-150;

                @include breakpoint(sm) {
                    padding: 32px;
                }
            }
            .card-body {
                padding: 0;
                p {
                    color: #8f8f8f;
                }
            }
        }
    }
    .card-feature-2,
    .whatis {
        &.frame-type-card_group {
            .container--wide {
                max-width: 1505px;
                .card-group-element {
                    @include breakpoint(xl) {
                        margin-left: -80px;
                        max-width: calc(100% + 80px);
                    }
                    @include breakpoint(2xl) {
                        margin-left: -100px;
                        max-width: calc(100% + 100px);
                    }
                    &.card-group-element-columns-3 {
                        .card-group-element-item {
                            @include breakpoint(xl) {
                                margin-left: 80px;
                                margin-bottom: 52px;
                                width: calc(33% - 80px);
                            }
                            @include breakpoint(2xl) {
                                margin-left: 100px;
                                width: calc(33% - 100px);
                            }
                        }
                    }
                }
            }
        }
        .card-title {
            margin-bottom: 8px;

            line-height: 1.4;

            max-width: 100%;
        }
        .card-body {
            @include breakpoint(xl) {
                padding: 48px 32px;
            }
            p {
                line-height: 1.4;
                margin-top: 8px;
                margin-bottom: 0;

                color: #8f8f8f;
                @include breakpoint(sm) {
                    font-size: 2rem;
                }
                @include breakpoint(lg) {
                    font-size: 2.2rem;
                }
            }
        }
    }
    
    .tarifs {
        .card-group-element-item:nth-child(2) {
            .highlight {
                background-color: $colorSecondary;
            }

            * {
                color: $colorSecondary;

                border-color: $colorSecondary;
            }
        }
        .frame-footer {
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;

            max-width: 685px;

            @include breakpoint(md) {
                margin-top: 40px;
            }
            @include breakpoint(xl) {
                margin-top: 65px;
            }

            .highlight-padding {
                margin-top: 40px;
            }
        }
    }

    .whatis {
        .container--wide {
            max-width: 1505px;
        }
        .card-group-element-item {
            .card-body {
                position: relative;

                // &::after {
                //     content: url('/assets/svg/check.svg');

                //     width: 25px;

                //     position: absolute;
                //     top: 0;
                //     right: 0;

                //     border-radius: 50%;

                //     transform: translate(-50%, 50%);
                // }
            }

            .card-badge {
                $width: 25px;

                width: $width;

                position: absolute;
                top: calc($width / 2);
                right: calc($width / 2);
            }
        }
    }
    // Texte :::::::::::
    .frame-type-text {
        a {
            text-decoration: none !important;
        }
    }
    .card-reals {
        &.frame-type-card_group {
            .card-group-element {
                @include breakpoint(xl) {
                    margin-left: -80px;
                    max-width: calc(100% + 80px);
                }
                @include breakpoint(2xl) {
                    margin-left: -110px;
                    max-width: calc(100% + 110px);
                }
                &.card-group-element-columns-2 {
                    .card-group-element-item {
                        @include breakpoint(xl) {
                            margin-left: 80px;
                            margin-bottom: 52px;
                            width: calc(50% - 80px);
                        }
                        @include breakpoint(2xl) {
                            margin-left: 110px;
                            width: calc(50% - 110px);
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeslideUp {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    5% {
      opacity: 1;
      transform: translateY(0);
    }
    21% {
      opacity: 1;
      transform: translateY(0);
    }
    25% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 0;
      transform: translateY(10px);
    }
}