.home,
.layout-homepage {
    --secondary-font-size: 1.2rem; 

    .section {
        &.section-content-slider {
            .frame {
                padding: 0;
            }
        }
    }
    .frame {
        &:not(.frame-type-imageseparator):not(.frame-type-slider):not(.frame-type-sliderhome) {
            padding: 36px 0;
            @include breakpoint(md) {
                padding: 54px 0;
            }
            @include breakpoint(xl) {
                padding: 80px 0;
            }
            @include breakpoint(xl2) {
                padding: 100px 0;
            }
        }
        :not(.slider-section) {
            h2 {
                font-size: calc(1.875 * #{$fontSecondarySize});
                @include breakpoint(md) {
                    font-size: calc(2.5 * #{$fontSecondarySize});
                }
                @include breakpoint(xl) {
                    font-size: calc(3 * #{$fontSecondarySize});
                }
            }
        }
        &-type-textpic {
            .ce-textpic {
                @include breakpoint(md){
                    align-items: center;
                }
            }
        }
    }
    .slider-section,
    .mini-slider-section,
    .full-background {
        padding: 0;
    }
    .frame-type-icon_group {
        .icongroup-item-text {
            color: $black;
        }
    } 
}