.breadcrumb-section {
    display: none;

    padding-top: 20px;

    @include breakpoint(lg) {
        display: block;
    }
    .breadcrumb-container {
        width: 90%;
        max-width: 1140px;

        margin: 0 auto;
    }
    .breadcrumb {
        display: flex;
        list-style-type: none;

        padding: 0;

        font-style: italic;
        font-size: 1.4rem;

        a {
            color: $colorGray;
        }
        .breadcrumb-item {
            margin: 0;
            & + .breadcrumb-item {
                margin: 0 0 0 4px;
                &::before {
                    content: '|';
                    margin-right: 4px;
                }
            }
        }
    }
}