.btn {
    padding: 10px 30px;

    text-decoration: none;

    border-radius: 0;

    cursor: pointer;

    &--primary {
        background-color: $colorPrimary;
        color: $textColor;
        border: 1px solid $colorPrimary;
        &:hover {
            color: $colorPrimary;
            background-color: $textColor;
        }
    }

    &--primary-dark {
        background-color: $colorPrimaryDark;
        color: $white;
        border: 1px solid $colorPrimaryDark;
        &:hover {
            color: $colorPrimaryDark;
            background-color: $white;
        }
    }

    &--primary-light {
        background-color: $colorPrimaryLight;
        color: $black;
        border: 1px solid $colorPrimaryLight;
        &:hover {
            color: $colorPrimaryLight;
            background-color: $black;
        }
    }

    &--secondary {
        background-color: $colorSecondary;
        color: $white;
        border: 1px solid $colorSecondary;
        &:hover {
            color: $colorSecondary;
            background-color: $white;
        }
    }

    &--black {
        background-color: $black;
        color: $white;
        border: 1px solid $black;
        &:hover {
            color: $black;
            background-color: $white;
        }
    }

    &--white {
        background-color: $white;
        color: $black;
        border: 1px solid $black;
        &:hover {
            color: $white;
            background-color: $black;
        }
    }
    &--white-inverted {
        background-color: rgba(255,255,255,0);
        color: $white;
        border: 1px solid $white;
        &:hover {
            color: $white;
            background-color: rgba(255,255,255,0.3);
        }
    }
}

.variant--rounded {
    .btn {
        border-radius: 4px !important;
    }
}

.variant--oblong {
    .btn {
        border-radius: 999px !important;
    }
}